import { format, add } from "date-fns";
import { ru } from "date-fns/locale";
import styles from "./UpdateDateCard.module.css";
import { useEffect, useState } from "react";

/**
 * Компонент для отображения блока обновления списка.
 *
 * @param {Object} props Свойства компонента.
 * @param {string} props.date Дата обновления.
 * @return {JSX.Element} React-элемент блока обновления списка.
 */

export default function UpdateDateCard({ date }) {
  const [visibleIndicator, setVisibleIndicator] = useState(false);
  const [screenWidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );

  useEffect(() => {
    const setWindowDimensions = () =>
      setScreenWidth(document.documentElement.clientWidth);
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  useEffect(() => {
    const dateObj = new Date(date);
    const dateNow = new Date();

    if (add(dateObj, { months: 1 }) >= dateNow) setVisibleIndicator(true);
  }, [date]);

  function formattedDate(initialDate) {
    if (date) {
      const dateObj = new Date(initialDate);

      return screenWidth > 1024
        ? format(dateObj, "HH:mm - dd MMMM, yyyy", { locale: ru })
        : format(dateObj, "HH:mm dd.MM.yyyy", { locale: ru });
    }
    return "";
  }

  function dataSlice(data, start, end = data.length) {
    return data.slice(start, end);
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>ОБНОВЛЕНИЕ СПИСКА</h3>
      <div className={styles.date}>
        {screenWidth > 1024 ? (
          <>
            {visibleIndicator && <div className={styles.alert} />}
            {formattedDate(date)}
          </>
        ) : (
          <>
            <div>
              {visibleIndicator && <div className={styles.alert} />}
              {dataSlice(formattedDate(date), 0, 6)}
            </div>
            {dataSlice(formattedDate(date), 6)}
          </>
        )}
      </div>
    </div>
  );
}
