import { useEffect } from "react";
import { useDarkMode } from "usehooks-ts";

/**
 * Хук для установки иконки сайта в зависимости от темы.
 */
const useFaviconTheme = () => {
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    const favicon = document.querySelector("link[rel~='icon']");
    const theme = isDarkMode ? "dark" : "light";
    favicon.href = "favicon-" + theme + ".ico";

    return () => {
      favicon.href = "favicon-light.ico";
    };
  }, [isDarkMode]);
};

export default useFaviconTheme;
