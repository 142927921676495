import { useMemo } from "react";
import formatCode from "../../utils/formatCode";
import BottomSheet from "../BottomSheet/BottomSheet";
import InfoCard from "../ui/InfoCard/InfoCard";
import styles from "./TableBottomSheet.module.css";

/**
 * Компонент выдвижного снизу блока с информацией.
 *
 * @param {Object} props Свойства компонента.
 * @param {[]} props.list Информация о поступлении (отфильтрованные).
 * @param {{}} props.data Данные для шапки компонента.
 * @param {boolean} [props.isOpen=false] Флаг отображения компонента
 * @param {Function} props.onClose Функция для закрытия компонентаю
 * @return {JSX.Element} React-элемент выдвижного снизу блока с информацией.
 */

export default function TableBottomSheet({
  list,
  data,
  isOpen = false,
  onClose,
}) {
  const formattedCode = useMemo(() => formatCode(data?.code), [data]);

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={onClose}
      maxHeight={"60vh"}
      titleBlock={
        <div className={styles.titleBlockContainer}>
          <div className={styles.titleBlock}>
            <h1>{formattedCode}</h1>
            <div className={styles.infoCardContainer}>
              <InfoCard
                isStatus
                isSmall
                className={`${styles.infoCard} ${
                  data?.status?.type === 2
                    ? "successStatus"
                    : data?.status?.type === 3
                    ? "errorStatus"
                    : "defaultStatus"
                }`}
                title={data?.status?.name}
              />
              <InfoCard
                isSmall
                className={styles.infoCard}
                title={`#${data?.number} в списке`}
              />
            </div>
          </div>
          <h2 className={styles.listTitle}>Детали заявления</h2>
        </div>
      }
    >
      <div className={styles.listContainer}>
        {list?.map((item, index) => (
          <div
            key={index}
            className={`${styles.listCard} ${
              item?.value?.length > 42 ? styles.largeWidth : ""
            }`}
          >
            <h3>{item.title}</h3>
            <p>{item.value}</p>
          </div>
        ))}
      </div>
    </BottomSheet>
  );
}
