import { useEffect, useState } from "react";
import styles from "./SwitchButton.module.css";

/**
 * Компонент переключателя.
 *
 * @param {Object} props Свойства компонента.
 * @param {Function} props.onSwitchButton Функция, переключающая кнопки.
 * @param {[]} props.values Массив со значениями
 * @return {JSX.Element} React-элемент переключателя.
 */

export default function SwitchButton({ onSwitchButton, values }) {
  const [isSelectRightButton, setIsSelectRightButton] = useState(false);

  useEffect(() => {
    if (values?.length && values[0]?.isSelected) setIsSelectRightButton(false);
    if (values?.length && values[1]?.isSelected) {
      setIsSelectRightButton(true);
    }
  }, [values]);

  function handleClick() {
    if (values?.length > 1) {
      setIsSelectRightButton((prev) => !prev);
      onSwitchButton(isSelectRightButton);
    }
  }

  return (
    <div
      className={`${styles.switchButtonContainer} ${
        values?.length === 1 ? styles.switchButtonContainerSmall : ""
      }`}
    >
      <div
        onClick={handleClick}
        className={`${styles.switchButton} ${
          isSelectRightButton ? styles.switchOnRight : ""
        }`}
      ></div>
      <div
        className={`${styles.textContainer} ${
          values?.length === 1 ? styles.textContainerSmall : ""
        }`}
        onClick={handleClick}
      >
        <div
          className={`${styles.textButton} ${
            isSelectRightButton ? styles.notSelect : ""
          }`}
        >
          {values[0]?.name}
        </div>
        {values?.length > 1 && (
          <div
            className={`${styles.textButton} ${
              isSelectRightButton ? "" : styles.notSelect
            }`}
          >
            {values[1]?.name}
          </div>
        )}
      </div>
    </div>
  );
}
