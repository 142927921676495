import React, { useEffect, useState } from "react";
import styles from "./ErrorAlert.module.css";
import SVGIcon from "../SVGIcon";
import { useAppContext } from "../../App";

/**
 * Компонент всплывающего сообщения об ошибке.
 *
 * @param {Object} props Свойства компонента.
 * @param {string} props.children Текст уведомления.
 * @return {JSX.Element} React-элемент уведомления.
 */
const ErrorAlert = ({ children }) => {
  const [isShown, setIsShown] = useState(false);

  const { setErrorMessage } = useAppContext();

  useEffect(() => {
    setIsShown(children !== "");

    const timer = setTimeout(handleClose, 10000);

    return () => clearTimeout(timer);
  }, [children]);

  const handleClose = () => {
    setErrorMessage("");
    setIsShown(false);
  };

  return (
    <>
      {isShown && (
        <div className={styles.errorAlert}>
          <div className={styles.innerContainer}>
            <div className={styles.timeBar} />
            <div className={styles.content}>
              <div className={styles.contentLeft}>
                <SVGIcon name={"info"} />
                {children}
              </div>
              <div onClick={handleClose}>
                <SVGIcon className={styles.closeButton} name={"x-close"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorAlert;
