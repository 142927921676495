import React, { useEffect, useState } from "react";
import styles from "./SearchTemplate.module.css";
import Title from "../../components/ui/Title/Title";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import useTitle from "../../utils/useTitle";
import {
  AdmissionsCompany,
  BasisForAdmission,
  StudyForms,
  DefaultValue,
} from "../../utils/DataList";
import SelectGroup from "../../components/SelectGroup/SelectGroup";
import useApi from "../../utils/useApi";
import Spinner from "../../components/ui/Spinner/Spinner";
import { useAppContext } from "../../App";
import Button from "../ui/Button/Button";

/**
 * Компонент шаблона страниц с поиском.
 *
 * @param {Object} props Свойства компонента.
 * @param {Object} props.state Состояние страницы c поиском.
 * @param {Function} props.setState Функция для изменения состояния страницы поиска.
 * @param {React.children} props.children Дочерний компонент шаблона.
 * @param {string} props.title Заголовок страницы с поиском
 * @param {string} props.description Описание страницы с поиском
 * @param {Object} props.headerProps Параметры шапки страницы
 * @param {Object} props.footerProps Параметры подвала страницы
 * @return {JSX.Element} React-элемент шаблона страницы с поиском.
 */

const SearchTemplate = ({
  state,
  setState,
  children,
  title,
  description,
  headerProps,
  footerProps,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [isError, setIsError] = useState(false);

  const { getRequest, cancelSource } = useApi();

  const { setPagesState, pagesState, setErrorMessage, errorMessage } =
    useAppContext();

  useTitle("Выбор");

  // Слушаем изменение URL-параметров, делаем запрос для получения данных
  // и установки начального состояния селекторов с выбором

  useEffect(() => {
    const campaignUid = pagesState?.requestParams?.campaign ?? "";
    const basisUid = pagesState?.requestParams?.basis ?? "";
    const studyFormUid = pagesState?.requestParams?.studyForm ?? "";

    if (
      state[AdmissionsCompany.description].value === DefaultValue ||
      !campaignUid
    ) {
      setIsLoading(true);
      getRequest("/base-params/")
        .then((res) => {
          if (res && res !== "canceled") {
            let newState = state;
            const compaignsList = res?.campaigns.map((item, index) => ({
              name: item?.name,
              id: index,
              uid: item?.uid,
            }));

            const currentCompaign = compaignsList.filter(
              (item) => item?.uid === campaignUid
            );

            const currentCompaignId = currentCompaign?.[0]?.id;

            const studysFormsList = res?.campaigns?.[
              currentCompaignId
            ]?.studyForms.map((item, index) => ({
              name: item.name,
              id: index,
              uid: item.id,
            }));

            const currentStudyForm = studysFormsList?.filter(
              (item) => item?.uid === Number(studyFormUid)
            );

            const currentStudysFormsId = currentStudyForm?.[0]?.id;

            const basesList = res?.campaigns?.[currentCompaignId]?.studyForms?.[
              currentStudysFormsId
            ]?.bases.map((item, index) => ({
              name: item.name,
              id: index,
              uid: item.id,
            }));

            const currentBases = basesList?.filter(
              (item) => item?.uid === Number(basisUid)
            );

            if (currentCompaign?.length !== 0) {
              newState = onChangeSelectValue(
                currentCompaignId,
                AdmissionsCompany.description,
                res,
                newState
              );
              if (currentStudyForm?.length !== 0) {
                newState = onChangeSelectValue(
                  currentStudysFormsId,
                  StudyForms.description,
                  res,
                  newState
                );

                if (currentBases?.length !== 0) {
                  const currentBasisId = currentBases[0].id;

                  newState = onChangeSelectValue(
                    currentBasisId,
                    BasisForAdmission.description,
                    res,
                    newState
                  );
                }
              }

              setState({
                ...newState,
                [StudyForms.description]: {
                  ...newState[StudyForms.description],
                  isOpen: false,
                },
                [BasisForAdmission.description]: {
                  ...newState[BasisForAdmission.description],
                  isOpen: false,
                },
              });
            } else {
              const compaignsList = res?.campaigns?.map((item, index) => ({
                name: item.name,
                id: index,
                uid: item.uid,
              }));

              const studyFormsListFirst = res?.campaigns?.[0]?.studyForms?.map(
                (item, index) => ({
                  name: item.name,
                  id: index,
                  uid: item.id,
                })
              );

              const basisListFirst =
                res?.campaigns?.[0]?.studyForms?.[0]?.bases?.map(
                  (item, index) => ({
                    name: item.name,
                    id: index,
                    uid: item.id,
                  })
                );

              setState((prev) => ({
                ...prev,
                [AdmissionsCompany.description]: {
                  list: compaignsList?.length === 1 ? [] : compaignsList,
                  value:
                    compaignsList?.length === 1
                      ? compaignsList?.[0]
                      : DefaultValue,
                  isDisabled: false,
                  isOpen: false,
                },
                [StudyForms.description]: {
                  ...prev[StudyForms.description],
                  list:
                    studyFormsListFirst?.length === 1
                      ? []
                      : studyFormsListFirst,
                  value:
                    studyFormsListFirst === 1
                      ? studysFormsList?.[0]
                      : DefaultValue,
                  isDisabled: studyFormsListFirst?.length === 1 ? false : true,
                  isOpen: false,
                },
                ...(studyFormsListFirst.length === 1
                  ? {
                      [BasisForAdmission.description]: {
                        value:
                          basisListFirst?.length === 1
                            ? basisListFirst?.[0]
                            : DefaultValue,
                        list:
                          basisListFirst?.length === 1 ? [] : basisListFirst,
                        isDisabled: basisListFirst?.length === 1 ? false : true,
                        isOpen: true,
                      },
                    }
                  : {
                      [BasisForAdmission.description]: {
                        value: DefaultValue,
                        list: [],
                        isDisabled: true,
                      },
                    }),
              }));
            }
            setData(res);
          } else if (res !== "canceled") {
            console.log(res);
            setIsError(true);
            setErrorMessage(res);
          }
        })
        .catch((error) => {
          setIsError(true);
          setErrorMessage("Произошла непредвиденная ошбка");

          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return () => cancelSource();
    }
  }, [pagesState?.requestParams]);

  // функция для обновления параметров url

  function updateSearchParams(newState) {
    const campaignUid =
      newState?.[AdmissionsCompany.description]?.value?.uid ?? "";
    const studyFormUid = newState?.[StudyForms.description]?.value?.uid ?? "";
    const basisUid =
      newState?.[BasisForAdmission.description]?.value?.uid ?? "";
    const newSearchParams = {
      ...(campaignUid ? { campaign: campaignUid.toString() } : ""),
      ...(studyFormUid ? { studyForm: studyFormUid.toString() } : ""),
      ...(basisUid ? { basis: basisUid.toString() } : ""),
    };
    setPagesState((prev) => ({
      ...prev,
      requestParams: newSearchParams,
    }));
  }

  // функция для изменения значений селекторов

  function onChangeSelectValue(id, type, resData, selectData = state) {
    if (type === AdmissionsCompany.description) {
      const studyFormsList = resData?.campaigns[id].studyForms.map(
        (item, index) => ({
          name: item.name,
          id: index,
          uid: item.id,
        })
      );

      const basesListFirstForm = resData?.campaigns[id].studyForms[0].bases.map(
        (item, index) => ({
          name: item.name,
          id: index,
          uid: item.id,
        })
      );

      selectData = {
        ...selectData,
        [type]: {
          ...selectData[type],
          value: {
            id: id,
            name: resData.campaigns[id].name,
            uid: resData.campaigns[id].uid,
          },
          list: resData.campaigns
            .map((item, index) => ({
              name: item.name,
              id: index,
              uid: item.uid,
            }))
            .filter((item) => item.id !== id),
          isDisabled: false,
        },
        [StudyForms.description]: {
          value: studyFormsList.length === 1 ? studyFormsList[0] : DefaultValue,
          list: studyFormsList.length === 1 ? [] : studyFormsList,
          isDisabled: false,
          isOpen: studyFormsList === 1 ? false : true,
        },
        ...(studyFormsList.length === 1
          ? {
              [BasisForAdmission.description]: {
                value:
                  basesListFirstForm.length === 1
                    ? basesListFirstForm[0]
                    : DefaultValue,
                list: basesListFirstForm.length === 1 ? [] : basesListFirstForm,
                isDisabled: false,
                isOpen: true,
              },
            }
          : {
              [BasisForAdmission.description]: {
                value: DefaultValue,
                list: [],
                isDisabled: true,
              },
            }),
      };
      updateSearchParams(selectData);
      return selectData;
    }
    if (type === StudyForms.description) {
      const admissinCompanyIndex =
        selectData[AdmissionsCompany.description].value.id;

      const basisForAdmissionList = resData.campaigns[
        admissinCompanyIndex
      ].studyForms[id].bases.map((item, index) => ({
        id: index,
        name: item.name,
        uid: item.id,
      }));

      selectData = {
        ...selectData,
        [type]: {
          ...selectData.type,
          value: {
            id: id,
            name: resData.campaigns[admissinCompanyIndex].studyForms[id].name,
            uid: resData.campaigns[admissinCompanyIndex].studyForms[id].id,
          },
          list: resData.campaigns[admissinCompanyIndex].studyForms
            .map((item, index) => ({
              name: item.name,
              id: index,
              uid: item.uid,
            }))
            .filter((item) => item.id !== id),
        },
        [BasisForAdmission.description]: {
          ...selectData[BasisForAdmission.description],
          value:
            basisForAdmissionList.length === 1
              ? basisForAdmissionList[0]
              : DefaultValue,
          list: basisForAdmissionList.length === 1 ? [] : basisForAdmissionList,
          isDisabled: false,
          isOpen: basisForAdmissionList === 1 ? false : true,
        },
      };
      updateSearchParams(selectData);

      return selectData;
    }
    if (type === BasisForAdmission.description) {
      const admissinCompanyIndex =
        selectData[AdmissionsCompany.description].value.id;

      const studyFormIndex = selectData[StudyForms.description].value.id;

      selectData = {
        ...selectData,
        [type]: {
          ...selectData.type,
          value: {
            id: id,
            name: resData.campaigns[admissinCompanyIndex].studyForms[
              studyFormIndex
            ].bases[id].name,
            uid: resData.campaigns[admissinCompanyIndex].studyForms[
              studyFormIndex
            ].bases[id].id,
          },
          list: resData.campaigns[admissinCompanyIndex].studyForms[
            studyFormIndex
          ].bases
            .map((item, index) => ({
              name: item.name,
              id: index,
              uid: item.id,
            }))
            .filter((item) => item.id !== id),
        },
      };
      updateSearchParams(selectData);

      return selectData;
    }

    return {};
  }

  /**
   * Компонент обложки с подсказкой для поиска расписания.
   *
   * @return {JSX.Element} React-элемент текста обложки.
   */
  const Cover = () => {
    const Description = () => {
      return <div className={styles.infoDescription}>{description}</div>;
    };

    return (
      <div className={styles.infoContainer}>
        <Title level={1}>{title}</Title>
        <Description />
      </div>
    );
  };

  function showSearch() {
    if (
      state[AdmissionsCompany.description].value === DefaultValue ||
      state[StudyForms.description].value === DefaultValue ||
      state[BasisForAdmission.description].value === DefaultValue
    ) {
      return false;
    }

    return true;
  }

  const handleClickReloadPage = () => window.location.reload();

  return (
    <>
      <Header {...headerProps} />
      <main
        className={`${styles.main} ${
          isLoading || isError ? styles.center : ""
        }`}
      >
        {isLoading && <Spinner />}
        {isError && !(errorMessage?.length > 0) && (
          <Button
            spaceV={10}
            spaceH={16}
            fontSize={15}
            radius={6}
            gap={8}
            onClick={handleClickReloadPage}
          >
            Перезагрузить страницу
          </Button>
        )}
        {!isLoading && !isError && !(errorMessage?.length > 0) && (
          <>
            <Cover />
            <div className={styles.mainContainer}>
              <div className={styles.selectContainer}>
                <SelectGroup
                  state={state}
                  setState={setState}
                  data={data}
                  onChangeValue={onChangeSelectValue}
                />
              </div>
              {showSearch() && children}
            </div>
          </>
        )}
      </main>
      <Footer {...footerProps} />
    </>
  );
};
export default SearchTemplate;
