import RadioButton from "../ui/RadioButton/RadioButton";
import Spinner from "../ui/Spinner/Spinner";
import styles from "./RadioButtonsGroup.module.css";

export default function RadioButtonsGroup({
  data,
  type = "radio",
  onChange,
  isLoading = false,
}) {
  function isAllDisabledInGroup() {
    let itemsDisebledCount = 0;

    data?.values?.length > 0 &&
      data?.values?.forEach((element) => {
        if (!element?.isActive) itemsDisebledCount++;
      });

    return itemsDisebledCount === data?.length;
  }

  return (
    <div className={styles.radioButtonsGroup}>
      <h4
        className={`${styles.title} ${
          isAllDisabledInGroup() ? styles.titleDisabled : ""
        }`}
      >
        {data?.name}
      </h4>
      {/* <div className={styles.radioButtonContainer}>
        <div
          className={`${styles.radioButtonWrapper} ${
            isLoading ? styles.hidden : ""
          }`}
        > */}
      {data?.values?.length > 0 &&
        data?.values?.map((item, index) => (
          <RadioButton
            id={index}
            key={index}
            value={item?.name}
            checked={data?.values?.[index]?.isSelected}
            name={data?.key}
            onChange={onChange}
            isDisabled={!item?.isActive}
            type={type}
            isLoading={isLoading}
          />
        ))}
      {/* </div>
        {isLoading && (
          <div className={styles.loader}>
            {data?.values?.length > 0 && data?.values?.map((_,index)=>(
              <div></div>
            ))}
          </div>
        )}
      </div> */}
    </div>
  );
}
