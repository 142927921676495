import { useEffect } from "react";

/**
 * Хук для установки заголовка страницы.
 *
 * @param {string} text Текст, который будет добавлен к заголовку страницы.
 */
const useTitle = (text) => {
  useEffect(() => {
    const defaultTitle = "Списки поступающих";
    document.title = text ? text + " — " + defaultTitle : defaultTitle;
  }, [text]);
};

export default useTitle;
