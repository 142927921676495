import SVGIcon from "../../SVGIcon";
import styles from "./RadioButton.module.css";
import { motion } from "framer-motion";

const animation = {
  initial: { opacity: 0.1 },
  animate: { opacity: 0.5 },
  transition: {
    duration: 1,
    type: "keyframes",
    repeat: Infinity,
    ease: "easeInOut",
  },
};

export default function RadioButton({
  id,
  value,
  checked = false,
  name,
  isDisabled = false,
  onChange,
  type = "radio",
  isLoading = false,
}) {
  return (
    <div className={styles.radioButtonContainer}>
      <>
        <input
          id={`${id}-${value}`}
          name={`${name}`}
          type={type}
          checked={checked}
          value={value}
          className={styles.radioButton}
          onChange={onChange}
          disabled={isDisabled}
        />
        <motion.label
          initial={{ opacity: 0 }}
          animate={!isLoading ? { opacity: 1 } : { opacity: 0 }}
          transition={{
            duration: 0.3,
          }}
          htmlFor={`${id}-${value}`}
          className={`${styles.radioButtonLabel} ${
            isLoading ? styles.hidden : ""
          }`}
        >
          <div
            className={`${
              type === "radio"
                ? styles.customRadioButton
                : styles.customCheckbox
            }`}
          >
            {checked && type === "checkbox" ? (
              <SVGIcon name="check-fat" size={"10px"} />
            ) : (
              ""
            )}
          </div>
          {value}
        </motion.label>
      </>
      {isLoading && (
        <div
          className={`${styles.radioButtonLabel} ${styles.skeletonLoadingContainer}`}
        >
          <motion.div
            {...animation}
            className={`${
              type === "radio"
                ? styles.customRadioButton
                : styles.customCheckbox
            }`}
          ></motion.div>
          <motion.div {...animation} className={styles.skeletonLoading} />
        </div>
      )}
    </div>
  );
}
