import React from "react";
import { Link as RouterLink } from "react-router-dom";

/**
 * Компонент ссылки.
 *
 *  * @param {Object} props Свойства компонента.
 * @param {React.ReactNode} props.content Вложенный контент ссылки.
 * @param {string} props.className Класс для ссылки.
 * @param {string} props.url URL, на который ссылается ссылка.
 * @param {boolean} props.newTab Флаг, указывающий на открытие ссылки в новой вкладке.
 * @param {Function} props.onClick Функция, вызываемая при клике на ссылку
 * @return {Element} Элемент ссылки.
 */
const Link = ({ children: content, className, url, newTab, onClick }) => {
  const target = newTab ? "_blank" : null;
  const rel = target ? "noreferrer noopener" : null;

  return (
    <RouterLink
      className={className}
      to={url}
      target={target}
      rel={rel}
      onClick={onClick}
    >
      {content}
    </RouterLink>
  );
};

export default Link;
