/**
 * Объект, представляющий идентификаторы модальных окон.
 */
const ModalId = {
  DEV_TEAM: 1,
  CONTACTS: 2,
  HOW_ADMISSION_WORK: 3,
  MORE_INFO: 4,
};

export default ModalId;
