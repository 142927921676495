import React, { useEffect, useState } from "react";
import styles from "./Footer.module.css";
import Logo from "../ui/Logo/Logo";
import { useAppContext } from "../../App";
import ModalId from "../../utils/ModalId";
import SearchBySnils from "../SearchBySnils/SearchBySnils";
import Link from "../ui/Link";
import Button from "../ui/Button/Button";
import { useAppNav } from "../../utils/useAppNav";

/**
 * Компонент для отображения подвала.
 *
 * @param {Object} props Свойства компонента.
 * @param {boolean} props.logo Флаг, указывающий на отображение логотипа.
 * @param {boolean} props.isNavToOrders Флаг, указывающий на отображение кнопки перехода на страницу поиска приказов.
 * @param {boolean} props.isSnilsSearchResultPage Флаг, указывающий на страницу результатов поиска по СНИЛС\УКП.
 * @return {JSX.Element} React-элемент подвала.
 */
const Footer = ({
  logo,
  isNavToOrders = true,
  isSnilsSearchResultPage = false,
}) => {
  const { showModal, pagesState } = useAppContext();

  const { navToOrdersList } = useAppNav();

  const [screenWidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );

  useEffect(() => {
    const setWindowDimensions = () =>
      setScreenWidth(document.documentElement.clientWidth);

    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  function openModal(e, id) {
    e.preventDefault();
    showModal(id);
  }

  /**
   * Компонент для отображения контактов.
   *
   * @return {JSX.Element} React-элемент контактов.
   */
  const Contacts = () => {
    const handleClick = (e) => {
      openModal(e, ModalId.CONTACTS);
    };

    return (
      <div className={styles.copyrightText}>
        <Link onClick={handleClick}>Контакты</Link>
      </div>
    );
  };

  /**
   * Компонент для отображения копирайта.
   *
   * @return {JSX.Element} React-элемент копирайта.
   */
  const Copyright = () => {
    const handleClick = (e) => openModal(e, ModalId.DEV_TEAM);

    return (
      <div className={styles.copyrightText}>
        Разработано в <Link onClick={handleClick}>ФГБОУ «КГТУ»</Link>
      </div>
    );
  };

  function navigateToOrders() {
    if (isSnilsSearchResultPage) {
      navToOrdersList(pagesState?.code ? { search: pagesState.code } : {});
    } else {
      navToOrdersList();
    }
  }

  return (
    <footer className={styles.footer}>
      <div
        style={
          !isNavToOrders
            ? screenWidth <= 500
              ? logo
                ? { height: "92px" }
                : { height: "100px" }
              : screenWidth <= 640
              ? logo
                ? { height: "100px" }
                : { height: "110px" }
              : {}
            : {}
        }
        className={`${logo ? styles.innerContainerMain : styles.innerContainer}
        ${logo && screenWidth > 1024 && styles.paddingLeftLarge} `}
      >
        {logo ? <Logo /> : <SearchBySnils />}
        <div className={styles.infoContainer}>
          {isNavToOrders && (
            <Button
              outline
              className={styles.ordersButton}
              radius={"6px"}
              spaceH={screenWidth > 1024 ? "16px" : "12px"}
              spaceV={screenWidth > 1024 ? "9.5px" : "8px"}
              fontSize={screenWidth > 1024 ? "15px" : "12px"}
              onClick={navigateToOrders}
            >
              Приказы о зачислении
            </Button>
          )}
          <div
            className={
              logo ? styles.copyrightContainerMain : styles.copyrightContainer
            }
          >
            <Contacts />
            <Copyright />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
