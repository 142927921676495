import { useEffect, useRef, useState } from "react";
import SVGIcon from "../../SVGIcon";
import styles from "./SnilsInput.module.css";

/**
 * Компонент для отображения поиска по СНИЛС/УКП.
 *
 * @param {Object} props Свойства компонента.
 * @param {string} props.inputValue Значение поиска
 * @param {Function} props.onChange Функция, вызываемая при изменение значения поиска
 * @param {Function} props.onClick Функция, вызываемая при нажатии на кнопку вывода резульатата поиска
 * @param {boolean} props.isDisabled  Флаг для блокировки поиска
 * @param {boolean} props.showErrorMessage  Флаг для активации сообщения об ошибке
 * @return {JSX.Element} React-элемент поиска по СНИЛС/УКП.
 */
export default function SnilsInput({
  inputValue,
  onChange,
  onClick,
  isDisabled = false,
  showErrorMessage = false,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isAutoFocus, setIsAutoFocus] = useState(false);
  const [inputCursorPos, setInputCursorPos] = useState(0);

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputValue?.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [inputValue]);

  /**
   * Компонент для отображения поля для ввода поискового запроса.
   *
   * @return {JSX.Element} React-элемент поля ввода.
   */
  const Input = () => {
    const handleChangeValue = (e) => {
      if (isAutoFocus === false) setIsAutoFocus(true);
      onChange(e);
      if (inputValue.length < 10) {
        if (e.target.selectionStart > 9) {
          setInputCursorPos(e.target.selectionStart + 3);
        } else if (e.target.selectionStart > 11) {
          setInputCursorPos(11 + 3);
        } else {
          setInputCursorPos(e.target.selectionStart);
        }
      } else {
        if (
          e.target.selectionStart === 4 ||
          e.target.selectionStart === 8 ||
          e.target.selectionStart === 12
        ) {
          setInputCursorPos(e.target.selectionStart + 1);
        } else {
          setInputCursorPos(e.target.selectionStart);
        }
      }
    };

    function handlekeyDown(e) {
      if (e.key === "Enter") {
        onClick();
      }
    }

    useEffect(() => {
      if (inputRef?.current) {
        inputRef?.current.setSelectionRange(inputCursorPos, inputCursorPos);
      }
    }, []);

    return (
      <input
        ref={inputRef}
        className="input"
        type="text"
        inputMode="numeric"
        value={inputValue}
        placeholder={"Поиск по СНИЛС/УКП"}
        onChange={handleChangeValue}
        autoFocus={isAutoFocus}
        onKeyDown={handlekeyDown}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div
        className={
          isDisabled
            ? "inputGroupDisabled "
            : "inputGroup " + styles.snilsInputGroup
        }
      >
        <Input />
        <button
          className={styles.button}
          disabled={isButtonDisabled}
          onClick={onClick}
        >
          <SVGIcon
            name={"magnifying-glass"}
            className={styles.buttonIcon}
            size={16}
          />
        </button>
      </div>
      {showErrorMessage && (
        <p className={styles.searchTooltip}>Неверно указан СНИЛС или УКП</p>
      )}
    </div>
  );
}
