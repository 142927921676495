import React, { useState } from "react";
import useTitle from "../../utils/useTitle";
import { initialSelectsState } from "../../utils/DataList";
import SearchGroup from "../../components/SearchGroup/SearchGroup";
import SearchTemplate from "../../components/SearchTemplate/SearchTemplate";

/**
 * Компонент страницы поиска.
 *
 * @return {JSX.Element} React-элемент страницы.
 */

const SearchPage = () => {
  const [state, setState] = useState(initialSelectsState);

  useTitle("Выбор");

  return (
    <>
      <SearchTemplate
        state={state}
        setState={setState}
        title={"Выберите образовательную программу"}
        description={
          "Укажите параметры поступления, чтобы сформировать актуальный список поступающих и узнать свое место"
        }
      >
        <SearchGroup state={state} />
      </SearchTemplate>
    </>
  );
};
export default SearchPage;
