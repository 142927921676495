import styles from "./InfoCard.module.css";
import { motion } from "framer-motion";

/**
 * Компонент карточки, отображающей значение.
 *
 * @param {Object} props Свойства компонента.
 * @param {React.ReactNode} props.title Значение в карточке.
 * @param {boolean} props.isSmall Флаг, обозначающий уменьшенные размеры карточки
 * @param {boolean} props.isStatus Флаг, обозначающий отображение статуса
 * @param {string} props.className Стилизация карточки
 * @param {Object} props.animate Объект с настройками анимации
 * @return {JSX.Element} React-элемент картчки.
 */

export default function InfoCard({
  title,
  isSmall = false,
  isStatus = false,
  className = "",
  animate = {},
}) {
  return (
    <motion.div
      {...animate}
      className={`  ${className} ${
        isStatus ? styles.containerIsStatus : styles.container
      } ${isSmall ? styles.small : ""}`}
    >
      {title}
    </motion.div>
  );
}
