import React from "react";
import styles from "./Title.module.css";

/**
 * Компонент для отображения заголовка с заданным уровнем и текстом.
 *
 * @param {Object} props Свойства компонента.
 * @param {string} props.children Текст заголовка.
 * @param {number} props.level Уровень заголовка от 1 до 6.
 * @return {JSX.Element} Возвращает JSX-элемент заголовка.
 */
const Title = ({ children, level}) => {
  const className = `${styles.title} ${styles["size" + level]}`;
  
  return <span className={className}>{children}</span>;
}

export default Title;
