import { useEffect, useState } from "react";
import SVGIcon from "../../SVGIcon";
import styles from "./ListsVariantsSelect.module.css";
import List from "../List/List";
import CountItem from "../CountItem/CountItem";
import ModalId from "../../../utils/ModalId";
import { useAppContext } from "../../../App";
import useKeyDown from "../../../utils/useKeyDown";
import { AnimatePresence } from "framer-motion";

/**
 * Компонент отражающий селектор со списком вариантов отображения табличных данных.
 *
 * @param {Object} props Свойства компонента.
 * @param {value[]} props.list Данные для вариантов списков.
 * @param {object} props.value Выбранное значение селектора.
 * @param {Function} props.onClickItem Функция для изменения значения селектора.
 * @param {Function} props.count Кол-во абитурентов в выбранном варианте списка.
 * @return {JSX.Element} React-элемент блока c вариантами списков.
 */

export default function ListsVariantsSelect({
  list,
  value,
  onClickItem,
  count,
}) {
  const [isOpenList, setIsOpenList] = useState(false);

  const { showModal } = useAppContext();

  const [isKeyActivated, setIsKeyActivated] = useState(false);

  const { handleKeyDown } = useKeyDown({ isKeyActivated, setIsKeyActivated });

  useKeyDown({ isKeyActivated, setIsKeyActivated });

  function handleClick() {
    setIsOpenList((prev) => !prev);
  }

  function onCloseList() {
    setIsOpenList(false);
    setIsKeyActivated(false);
  }

  useEffect(() => {
    if (!isOpenList) {
      setIsKeyActivated(false);
    }
  }, [isOpenList]);
  const handleOpenModal = () => showModal(ModalId.MORE_INFO);

  return (
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        <button onClick={handleOpenModal} className={styles.infoButton}>
          <SVGIcon
            className={styles.infoList}
            name={"info-lists"}
            size={"20px"}
          />
          <p className={styles.popover}>Подробнее о вариантах списков</p>
        </button>
        <button
          className={styles.button}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          {value.name}
          <div className={styles.button}>
            <CountItem>{count}</CountItem>
            {list?.length > 0 &&
              (isOpenList ? (
                <SVGIcon className={styles.arrow} name={"chevron-up"} />
              ) : (
                <SVGIcon className={styles.arrow} name={"chevron-down"} />
              ))}
          </div>
        </button>
      </div>
      <AnimatePresence>
        {isOpenList && list?.length > 0 && (
          <div className={styles.listContainer}>
            <List
              onClickItem={onClickItem}
              onCloseList={onCloseList}
              list={list}
              nowrap
              isAbsolute
              isKeyActivated={isKeyActivated}
            />
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}
