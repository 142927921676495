import SVGIcon from "../../SVGIcon";
import styles from "./MessageBlock.module.css";

/**
 * Компонент отражающий информационный блок c сообщениями.
 *
 * @param {Object} props Свойства компонента.
 * @param {[]} props.msgList список сообщений.
 * @return {JSX.Element} React-элемент информационный блока.
 */

export default function MessageBlock({ msgList }) {
  return (
    <div className={styles.container}>
      <ul
        className={`${
          msgList?.length > 1 ? styles.dashStyleList : styles.notStyleList
        }`}
      >
        {msgList?.map((item, index) => (
          <li key={index}>
            <SVGIcon name="annotation-alert" />
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
