import { useEffect } from "react";

/**
 * Хук для активации перемещения по списку с помощью клавиатуры
 * 
 * @param {Object} props Свойства компонента.
 * @param {boolean} props.isKeyActivated Флаг для активации перемещения по списку с помощью клавиатуры
 * @param {boolean} props.setIsKeyActivated Функция для включения\выключения возможности перемещения по списку с помощью клавиатуры

 */
const useKeyDown = ({ isKeyActivated, setIsKeyActivated }) => {
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setIsKeyActivated(true);
    } else {
      setIsKeyActivated(false);
    }
  };

  useEffect(() => {
    if (isKeyActivated) {
      document.querySelector("#list")?.focus();
    } else {
      document.querySelector("#list")?.blur();
    }
  }, [isKeyActivated]);

  return { handleKeyDown };
};

export default useKeyDown;
