import { createContext, useContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styles from "./App.module.css";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import SearchPage from "./pages/SearchPage/SearchPage";
import ModalId from "./utils/ModalId.js";
import DevTeamModal from "./modals/DevTeamModal/DevTeamModal";
import ErrorAlert from "./components/ErrorAlert/ErrorAlert";
import ApplicantsLists from "./pages/ApplicantsLists/ApplicantsLists.jsx";
import Contacts from "./modals/Contacts/Contacts.jsx";
import HowAdmissionWork from "./modals/HowAdmissionWork/HowAdmissionWork.jsx";
import MoreInfo from "./modals/MoreInfo/MoreInfo.jsx";
import SnilsSearchResults from "./pages/SnilsSearchResults/SnilsSearchResults.jsx";
import useFaviconTheme from "./utils/useFaviconTheme.js";
import OrdersPage from "./pages/OrdersPage/OrdersPage.jsx";

const AppContext = createContext(null);

export const useAppContext = () => useContext(AppContext);

function App() {
  const [errorMessage, setErrorMessage] = useState("");
  const [visibleModalId, setVisibleModalId] = useState(null);
  const [moreInfoModalData, setMoreInfoModalData] = useState(null);
  const [pagesState, setPagesState] = useState({
    code: "",
    category: "",
    requestParams: {},
  });

  const Modals = () => {
    const handleClose = () => setVisibleModalId(null);

    const isVisible = visibleModalId !== null;
    const list = {
      [ModalId.DEV_TEAM]: (
        <DevTeamModal isVisible={isVisible} onClose={handleClose} />
      ),
      [ModalId.CONTACTS]: (
        <Contacts isVisible={isVisible} onClose={handleClose} />
      ),
      [ModalId.HOW_ADMISSION_WORK]: (
        <HowAdmissionWork isVisible={isVisible} onClose={handleClose} />
      ),
      [ModalId.MORE_INFO]: (
        <MoreInfo
          isVisible={isVisible}
          onClose={handleClose}
          data={moreInfoModalData}
        />
      ),
    };

    return visibleModalId && list[visibleModalId];
  };

  const showModal = (id) => setVisibleModalId(id);

  const contextValues = {
    setErrorMessage,
    showModal,
    setMoreInfoModalData,
    errorMessage,
    pagesState,
    setPagesState,
  };

  useFaviconTheme();

  return (
    <AppContext.Provider value={contextValues}>
      <BrowserRouter>
        <div className={`${styles.container}`}>
          <Routes>
            <Route path="/" element={<SearchPage />} />
            <Route path="/applicants-lists" element={<ApplicantsLists />} />
            <Route path="/applicant" element={<SnilsSearchResults />} />
            <Route path="/orders" element={<OrdersPage />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>

          <ErrorAlert>{errorMessage}</ErrorAlert>
          <Modals />
        </div>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
