import StatusButton from "../ui/StatusButton/StatusButton";
import styles from "./ApplicantsTable.module.css";
import formatCode from "../../utils/formatCode";
import TableList from "../ui/TableList/TableList";
import { useEffect, useMemo, useRef, useState } from "react";
import SVGIcon from "../SVGIcon";
import { useAppNav } from "../../utils/useAppNav";
import TableBottomSheet from "../TableBottomSheet/TableBottomSheet";

/**
 * Компонент отоюражающий блок c таблицей поступающих.
 *
 * @param {Object} props Свойства компонента.
 * @param {[]} props.data Данные для таблицы.
 * @param {[]} props.additionalColumns Дополнительные колонки
 * @param {boolean} [props.isMultipleTables=false] Флаг, обозначающий наличие на странице несколько таблиц
 * @param {string} [props.searchValue=''] Значение поля поиска по СНИЛС/УКП
 * @param {string} props.selectedCategoryID Значение выбранной категории
 * @param {string} props.screenWidth Значение ширины экрана
 * @param {string} props.screenHeight Значение высоты экрана
 * @return {JSX.Element} React-элемент блока c таблицей поступающих.
 */

export default function ApplicantsTable({
  data,
  additionalColumns,
  isMultipleTables = false,
  searchValue = "",
  selectedCategoryID,
  screenWidth,
  screenHeight,
}) {
  const [list, setList] = useState({
    isVisible: false,
    index: null,
    cursorTop: 0,
    bottom: "",
    right: "",
  });

  const [searchRowIndex, setSearchRowIndex] = useState(null);

  // состояние размеров таблицы: высота строки заголовков, высота строки тела таблицы, максимальные высоты таблицы
  const [tableSizes, setTableSizes] = useState({
    rowHeightHead: 48,
    rowHeightBody: 64,
    maxHeightTableStyle: {
      maxHeight: `calc(48px + 64px * 10)`,
    },
    maxHeightMultipleTableStyle: {
      maxHeight: `calc(48px + 64px * 5)`,
    },
  });

  const { navToSnilsSearchResults } = useAppNav();

  const tableRef = useRef(null);

  // установка значений размеров таблицы
  useEffect(() => {
    if (tableRef?.current) {
      const newRowHeadHeight =
        tableRef?.current?.getElementsByTagName("tr")[0]?.clientHeight;
      const newRowBodyHeight =
        tableRef?.current?.getElementsByTagName("tr")[1]?.clientHeight;

      if (newRowBodyHeight && newRowHeadHeight) {
        setTableSizes({
          rowHeightHead: newRowHeadHeight,
          rowHeightBody: newRowBodyHeight,
          maxHeightTableStyle: {
            maxHeight: `calc(${newRowHeadHeight}px + ${newRowBodyHeight}px * 10)`,
          },
          maxHeightMultipleTableStyle: {
            maxHeight: `calc(${newRowHeadHeight}px + ${newRowBodyHeight}px * 5)`,
          },
        });
      }
    }
  }, [data]);

  // оттображение в поле видимости искомого кода (СНИЛС/УКП), если он есть в таблице (скроллинг при необходимости)
  useEffect(() => {
    setSearchRowIndex(null);

    const unFormattedCode = searchValue.replace(/[^\d]/g, "");
    if (
      searchValue &&
      (unFormattedCode.length === 9 || unFormattedCode.length === 11)
    ) {
      let currentIndex = 0;
      let hasCode = false;

      data.forEach((item, index) => {
        if (item.code === unFormattedCode) {
          currentIndex = index;
          hasCode = true;
          setSearchRowIndex(index);
        }
      });

      if (hasCode === true) {
        if (screenWidth > 640) {
          document?.querySelector("#applicant-table-block")?.scrollIntoView({
            behavior: "auto",
            block: "start",
          });
        }

        let heightToCurrentRow = 0;

        for (let i = 1; i <= currentIndex; i++) {
          const newRowBodyHeight =
            tableRef?.current?.getElementsByTagName("tr")[i]?.clientHeight;
          heightToCurrentRow += newRowBodyHeight;
        }

        tableRef?.current?.querySelector("#table-wrapper-scrolled")?.scrollTo({
          top: heightToCurrentRow - 20,
          behavior: "smooth",
        });
      }
    } else {
      setSearchRowIndex(null);
      tableRef?.current?.querySelector("#table-wrapper-scrolled")?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [searchValue, selectedCategoryID]);

  // оттображение блока с информацией о статусе и тд при наведении на элемент статуса
  function onClickList(index) {
    return (e) => {
      if (!list.isVisible) {
        let tableWidth = 0;
        let left = 0;
        let cursorTop = e.clientY;
        if (tableRef?.current) {
          const targetElement =
            tableRef?.current?.querySelectorAll("button")?.[index];
          left = targetElement.offsetLeft;
          const rectTable = tableRef.current.getBoundingClientRect();
          tableWidth = rectTable.width;
        }
        const right = tableWidth - left;
        setList((prev) => ({
          ...prev,
          index,
          cursorTop,
          isVisible: true,
          right: right + 10,
        }));
      }
    };
  }

  useEffect(() => {
    if (list.isVisible && screenWidth >= 768) {
      let tableTop = 0;
      let tableHeight = 0;
      let listHeight = 0;

      if (tableRef?.current) {
        const rect = tableRef.current.getBoundingClientRect();
        const rectList = tableRef.current
          .querySelector("#table-list")
          ?.getBoundingClientRect();
        tableTop = rect.y;
        tableHeight = tableRef.current.offsetHeight;
        listHeight = rectList?.height;
      }

      let bottom = 0;

      bottom =
        tableHeight -
        (list.cursorTop - tableTop + tableSizes.rowHeightBody / 5);

      if (
        list.cursorTop - listHeight < 0 &&
        listHeight + list.cursorTop <= screenHeight
      ) {
        bottom =
          tableHeight -
          (list.cursorTop +
            listHeight -
            tableTop -
            tableSizes.rowHeightBody / 4);
      }

      setList((prev) => ({
        ...prev,
        bottom,
      }));
    }
  }, [list.index, list.isVisible]);

  // закрытие лсита для кнопки статус

  function onLeaveStatusButton(e) {
    if (
      screenWidth > 1024 &&
      list.isVisible &&
      tableRef?.current &&
      !tableRef?.current?.querySelector("#table-list")?.contains(e.target) &&
      !tableRef?.current
        ?.querySelectorAll("#row")
        ?.[list.index]?.contains(e.target)
    ) {
      onCloseList();
    }
  }

  useEffect(() => {
    if (screenWidth > 1024) {
      document.addEventListener("mousemove", onLeaveStatusButton);
      return () =>
        document.removeEventListener("mousemove", onLeaveStatusButton);
    }
  }, [list.isVisible]);

  // переход на страницу результатов поиска по СНИЛС/УКП при нажатии на строку тела таблицы
  function onClickSnilsRow(code) {
    return () => {
      if (code) {
        navToSnilsSearchResults({ code: code });
      }
    };
  }

  function onCloseList() {
    setList((prev) => ({
      ...prev,
      isVisible: false,
    }));
  }

  // выбор полей доп информации о статусе поступающего в зависимости от типа устройства
  const statusButtonFields = useMemo(() => {
    const allFields = data?.[list?.index]?.additionalFields;
    if (screenWidth > 1024) {
      return allFields?.filter((item) => item?.forDesktop === true);
    } else if (screenWidth >= 768) {
      return allFields?.length
        ? [
            ...allFields?.filter((item) => item?.title === "Статус"),
            ...allFields?.filter((item) => item?.title === "Номер по порядку"),
            ...allFields?.filter((item) => item?.forMobile === true),
          ]
        : [];
    } else {
      return allFields?.filter((item) => item?.forMobile === true);
    }
  }, [data, list.index, screenWidth]);

  // отформатированные коды (СНИЛС/УКП) поступающего для отображения в таблице
  const formattedTableCodes = useMemo(() => {
    const formattedCodes = data?.map((table) => {
      return { ...table, code: formatCode(table.code) };
    });
    return formattedCodes;
  }, [data]);

  return (
    <div ref={tableRef} className={styles.container}>
      <div
        style={data?.length === 0 ? tableSizes.maxHeightTableStyle : {}}
        className={`${styles.tableContainer} ${
          data?.length === 0 ? styles.center : ""
        }`}
      >
        {data?.length === 0 ? (
          <div className={`${styles.notList}`}>
            <SVGIcon name={"list-x"} size={56} />
            <div className={`${styles.notListText}`}>
              <h3>Поступающих пока нет</h3>
            </div>
          </div>
        ) : (
          <div
            id="table-wrapper-scrolled"
            onScroll={onCloseList}
            style={
              isMultipleTables
                ? tableSizes.maxHeightMultipleTableStyle
                : tableSizes.maxHeightTableStyle
            }
            className={`${styles.tableWrapper} customTableScroll 
            
            `}
          >
            <table
              className={`${styles.table} ${
                data?.length < 11 ? styles.rightPedding : ""
              }`}
            >
              <thead>
                <tr>
                  <th>№</th>
                  <th>СНИЛС/УКП</th>
                  {additionalColumns &&
                    screenWidth > 1024 &&
                    additionalColumns.map((item, index) => (
                      <th key={index}>{item.title}</th>
                    ))}
                  <th>Статус</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, index) => (
                    <tr
                      id="row"
                      key={index}
                      className={`${
                        searchRowIndex === index ? styles.searchRow : ""
                      } 
                        ${item?.color === "green" ? styles.greenRow : ""}
                        ${item?.color === "yellow" ? styles.yellowRow : ""}
                        `}
                    >
                      <td
                        onClick={onClickSnilsRow(item?.code)}
                        className={styles.numberRow}
                      >
                        {item?.number}
                      </td>
                      <td onClick={onClickSnilsRow(item?.code)}>
                        {formattedTableCodes[index].code}
                      </td>
                      {additionalColumns &&
                        screenWidth > 1024 &&
                        additionalColumns.map(({ key }, index) => (
                          <td
                            onClick={onClickSnilsRow(item?.code)}
                            key={index}
                            // className={`${
                            //   key === "recommendation"
                            //     ? styles.textAlignLeft
                            //     : ""
                            // }`}
                          >
                            {item[key]}
                          </td>
                        ))}
                      <td>
                        <div className={styles.buttonStatus}>
                          <StatusButton
                            type={item?.status?.type}
                            onClickList={onClickList(index)}
                            onCloseList={onCloseList}
                            isHover={screenWidth > 1024}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {list.isVisible && screenWidth >= 768 && (
        <TableList
          isListVisible={list.isVisible}
          bottom={list.bottom}
          right={list.right}
          list={statusButtonFields}
          onCloseList={onCloseList}
        />
      )}
      {
        <TableBottomSheet
          isOpen={list.isVisible && screenWidth < 768}
          onClose={onCloseList}
          list={statusButtonFields}
          data={data?.[list?.index]}
        />
      }
    </div>
  );
}
