import { useEffect, useRef } from "react";
import styles from "./TableList.module.css";
import Title from "../Title/Title";

/**
 * Компонент списка для таблицы.
 *
 * @param {Object} props Свойства компонента.
 * @param {value[]} props.list Выпадающий список.
 * @param {Function} props.onCloseList Функция для закрытия списка.
 * @param {string} props.bottom Положение списка относительно низа контейнера
 * @param {string} props.right Положение списка относительно правого края контейнера
 * @return {JSX.Element} React-элемент списка для таблицы.
 */

export default function TableList({
  list,
  onCloseList,
  bottom = "48",
  right = "48",
}) {
  const listRef = useRef(null);

  //   Закрытие списка при нажатии вне его области

  function onClose(e) {
    if (listRef?.current && !listRef?.current.contains(e.target)) {
      onCloseList();
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", onClose);
    return () => document.removeEventListener("mousedown", onClose);
  }, []);

  /**
   * Компонент для отображения сообщения об отсутствии результатов поиска.
   *
   * @return {JSX.Element} React-элемент сообщения.
   */
  const NotFoundMessage = () => {
    return (
      <div className={styles.notFoundContainer}>
        <Title level={2}>Нет данных</Title>
      </div>
    );
  };

  return (
    <div
      id="table-list"
      ref={listRef}
      className={`${styles.listContainer}`}
      style={{ bottom: `${bottom}px`, right: `${right}px` }}
    >
      {list && (
        <div className={`${styles.wrapper} customScrollList`}>
          {list.map(({ title, value }, index) => (
            <div key={index} className={styles.item}>
              <h3>{title}</h3>
              <p>{value}</p>
            </div>
          ))}
        </div>
      )}
      {!list && (
        <div
          className={`${styles.list} customScrollList ${styles.justifyCenter}`}
        >
          <NotFoundMessage />
        </div>
      )}
    </div>
  );
}
