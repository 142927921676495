import SVGIcon from "../../SVGIcon";
import styles from "./UpButton.module.css";

export default function UpButton({ onClick }) {
  return (
    <button className={styles.upButton} onClick={onClick}>
      <SVGIcon name="arrow-up" />
    </button>
  );
}
