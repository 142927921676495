import styles from "./CountItem.module.css";

/**
 * Компонент счетчика.
 *
 * @param {Object} props Свойства компонента.
 * @param {React.ReactNode} props.children Содержимое компонента
 * @param {string} props.isSelected Флаг для переключения стиля счетчика на выбранный
 * @param {string} props.isCountSmall Флаг для выбора уменьшенного стиля для счетчика
 * @return {JSX.Element} React-элемент счетчика.
 */

export default function CountItem({
  children,
  isSelected = true,
  isCountSmall = false,
}) {
  return (
    <span
      className={`${styles.numberCard} ${
        isSelected ? styles.selectedColor : ""
      } ${isCountSmall ? styles.countSmall : ""}`}
    >
      {children}
    </span>
  );
}
