import { format } from "date-fns";
import { ru } from "date-fns/locale";
import styles from "./OrderCard.module.css";
import SVGIcon from "../../SVGIcon";
import Link from "../Link";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

/**
 * Компонент карточки приказа.
 *
 * @param {Object} props Свойства компонента.
 * @param {object} props.data Данные для карточки приказа.
 * @return {JSX.Element} React-элемент карточки приказа.
 */

export default function OrderCard({ data, isOpenFullCard = false }) {
  const [isOpenFull, setIsOpenFull] = useState(isOpenFullCard);

  function toggleVisibleFullContent() {
    setIsOpenFull((prev) => !prev);
  }

  function formattedDate(initialDate) {
    if (initialDate) {
      const dateObj = new Date(initialDate);

      return format(dateObj, "dd MMMM, yyyy", { locale: ru });
    }
    return "";
  }

  function getcountEnrolledText(count) {
    if (count === 1) {
      return `[зачислен 1]`;
    } else {
      return `[зачислено ${count}]`;
    }
  }

  function getCategoriesTitleList(categories) {
    let listText = "";
    categories?.forEach((category, index) => {
      if (index < categories?.length - 1) {
        listText = `${listText}${category?.title}, `;
      } else {
        listText = `${listText}${category?.title}`;
      }
    });
    return listText;
  }

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardTitle}>
        <Link className={styles.linkToFile} url={data?.url} newTab>
          <SVGIcon name="paperclip" />
        </Link>
        <div className={styles.cardTitleText}>
          <span>{formattedDate(data?.date)}</span>
          <Link url={data?.url} className={styles.linkTitle} newTab>
            <h3>{data?.title}</h3>
          </Link>
        </div>
        <div className={styles.cardButton} onClick={toggleVisibleFullContent}>
          <span>{isOpenFull ? "Свернуть" : "Раскрыть"}</span>
          {isOpenFull ? (
            <SVGIcon name="chevron-up" />
          ) : (
            <SVGIcon name="chevron-down" />
          )}
        </div>
      </div>

      <AnimatePresence>
        {isOpenFull && (
          <motion.ul
            key={"fullCard"}
            initial={{ opacity: 0, height: 0 }}
            exit={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            transition={{
              duration: 0.2,
              opacity: { duration: 0.15 },
              type: "keyframes",
              ease: "linear",
            }}
            className={`${styles.cardCategoriesList} ${
              isOpenFull ? styles.cardCategoriesOpenList : ""
            }`}
          >
            {data?.categories?.map((category, index) => (
              <li key={index} className={styles.cardCategories}>
                <h3 className={styles.categoryName}>
                  <div className={styles.line} />
                  {category?.title}
                </h3>
                <div className={styles.categoryPrograms}>
                  {category?.competitionGroups?.map((group, index) => (
                    <div key={index} className={styles.categoryProgramGroup}>
                      {group?.title}{" "}
                      <span>{getcountEnrolledText(group?.countEnrolled)}</span>
                    </div>
                  ))}
                </div>
              </li>
            ))}
          </motion.ul>
        )}

        {!isOpenFull && (
          <motion.div
            key={"smallCard"}
            initial={isOpenFull ? { opacity: 0 } : { opacity: 1 }}
            animate={isOpenFull ? { opacity: 1 } : {}}
            transition={{ duration: 0.2 }}
            className={styles.cardCategoriesList}
          >
            <h3 className={styles.categoryName}>
              <div className={styles.line} />
              {getCategoriesTitleList(data?.categories)}
            </h3>
            <div
              className={styles.allCountApplicants}
            >{`Всего абитуриентов зачислено: ${data?.countEnrolled ?? 0}`}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
