import styles from "./Button.module.css";

const Button = ({
  children,
  outline,
  width,
  spaceH,
  spaceV,
  fontSize,
  radius,
  gap,
  onClick,
}) => {
  const className = `${styles.button} ${
    outline ? styles.outline : styles.solid
  }`;
  const style = {
    paddingTop: spaceV,
    paddingBottom: spaceV,
    paddingLeft: spaceH,
    paddingRight: spaceH,
    borderRadius: radius,
    fontSize: fontSize,
    width: width,
    gap: gap,
  };

  return (
    <button className={className} style={style} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
