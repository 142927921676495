/**
 * Объект, представляющий заголовок приемной компании и заголовок.
 */

export const AdmissionsCompany = {
  description: "ПРИЕМНАЯ КАМПАНИЯ",
};

/**
 *  Объект, представляющий заголовок форм обучения.
 */

export const StudyForms = {
  description: "ФОРМА ОБУЧЕНИЯ",
};
/**
 *  Объект, представляющий  заголовок основания поступления.
 */

export const BasisForAdmission = {
  description: "ОСНОВАНИЕ ПОСТУПЛЕНИЯ",
};

/**
 *  Объект, представляющий значение по умолчанию.
 */

export const DefaultValue = { id: 100, name: "Выбрать" };

export const initialSelectsState = {
  [AdmissionsCompany.description]: {
    list: [],
    value: DefaultValue,
    isDisabled: true,
  },
  [StudyForms.description]: {
    list: [],
    value: DefaultValue,
    isDisabled: true,
    isOpen: false,
  },
  [BasisForAdmission.description]: {
    list: [],
    value: DefaultValue,
    isDisabled: true,
    isOpen: false,
  },
};
