import React from "react";
import styles from "../Spinner/Spinner.module.css";

/**
 * Компонент спиннера загрузки.
 *
 * @param {Object} props Свойства компонента.
 * @param {boolean} props.alignCenter Флаг, указывающий на выравнивание по центру контейнера. По умолчанию `true`.
 * @return {JSX.Element} React-элемент спиннера.
 */
export default function Spinner({ alignCenter = true }) {
  const className = `${styles.spinner} ${alignCenter ? styles.center : ""}`;

  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path d="M24 6.00049C27.0798 6.00049 30.1081 6.79071 32.7952 8.29556C35.4823 9.80041 37.7383 11.9696 39.3475 14.5955C40.9567 17.2215 41.8652 20.2164 41.9861 23.2938C42.107 26.3712 41.4363 29.4282 40.0381 32.1723C38.6399 34.9164 36.561 37.2559 34.0003 38.9669C31.4395 40.678 28.4826 41.7034 25.4123 41.945C22.342 42.1866 19.261 41.6365 16.4641 40.3471C13.6672 39.0577 11.248 37.0722 9.4377 34.5806L12.6414 32.253C14.0534 34.1964 15.9404 35.7451 18.122 36.7508C20.3036 37.7565 22.7067 38.1857 25.1016 37.9972C27.4964 37.8087 29.8028 37.0089 31.8002 35.6743C33.7976 34.3397 35.4191 32.5149 36.5097 30.3745C37.6003 28.2341 38.1235 25.8497 38.0292 23.4493C37.9349 21.0489 37.2262 18.7129 35.9711 16.6646C34.7159 14.6164 32.9562 12.9244 30.8602 11.7506C28.7643 10.5769 26.4022 9.96049 24 9.96049V6.00049Z" />
      </svg>
    </div>
  );
}
