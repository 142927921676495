import React from "react";
import styles from "./DevTeamModal.module.css";
import ilyaPhoto from "./images/ilya_egorov.jpg";
import egorPhoto from "./images/egor_kalashnikov.jpg";
import nikitaPhoto from "./images/nikita_schastnev.jpg";
import iraPhoto from "./images/irina_zolotareva.jpg";
import Modal from "../../components/Modal/Modal";
import SVGIcon from "../../components/SVGIcon";
import Link from "../../components/ui/Link";

/**
 * Компонент модального окна с командой разработчиков.
 *
 * @param {Object} props Свойства компонента.
 * @param {boolean} props.isVisible Флаг, указывающий на показ окна.
 * @param {Function} props.onClose Обратный вызов, вызываемый при нажатии кнопки закрытия окна.
 * @return {JSX.Element} React-элемент модального окна.
 */
const DevTeamModal = ({ isVisible, onClose }) => {
  const Developers = () => {
    const Member = ({ children, photo, position, socials }) => {
      const LinkButton = ({ children, type }) => {
        const isEmail = type === "email";
        const url = isEmail ? `mailto:${children}` : children;

        return (
          <Link className={styles.socialButton} url={url} newTab={!isEmail}>
            <SVGIcon name={type} />
          </Link>
        );
      };

      return (
        <div className={styles.item}>
          <div className={styles.infoContainer}>
            <img className={styles.photo} src={photo} alt={""}></img>
            <div className={styles.textContainer}>
              <div className={styles.position}>{position}</div>
              <div className={styles.name}>{children}</div>
            </div>
          </div>
          <div className={styles.infoContainer}>
            {socials?.map(({ type, url }, index) => (
              <LinkButton key={index} type={type}>
                {url}
              </LinkButton>
            ))}
          </div>
        </div>
      );
    };

    const ilyaSocials = [
      { type: "site", url: "https://egoroffsoft.ru" },
      { type: "telegram", url: "https://t.me/isnotnil" },
      { type: "email", url: "ilya.egorov@klgtu.ru" },
    ];
    const iraSocials = [
      { type: "telegram", url: "https://t.me/irinaZoloto97" },
      { type: "email", url: "irina.zolotareva@klgtu.ru" },
    ];
    const egorSocials = [
      { type: "site", url: "https://sout.me" },
      { type: "telegram", url: "https://t.me/sout_me" },
      { type: "email", url: "kstuapp@sout.me" },
    ];
    const nikitaSocials = [
      { type: "site", url: "https://nixch.framer.website" },
      { type: "telegram", url: "https://t.me/nik_schastnev" },
    ];

    return (
      <div className={styles.members}>
        <Member
          photo={iraPhoto}
          position={"Frontend-разработчик"}
          socials={iraSocials}
        >
          Ирина Золотарева
        </Member>
        <Member
          photo={ilyaPhoto}
          position={"Frontend-разработчик"}
          socials={ilyaSocials}
        >
          Илья Егоров
        </Member>
        <Member
          photo={egorPhoto}
          position={"Backend-разработчик"}
          socials={egorSocials}
        >
          Егор Калашников
        </Member>
        <Member
          photo={nikitaPhoto}
          position={"Дизайнер интерфейса"}
          socials={nikitaSocials}
        >
          Никита Счастнев
        </Member>
      </div>
    );
  };
  const Links = () => {
    const ButtonLink = ({ children, url, solid, icon }) => {
      const className = `${styles.link} ${solid ? styles.solidLink : ""}`;
      const isSite = icon === "site";

      return (
        <Link className={className} url={url} newTab={isSite}>
          {children} <SVGIcon name={icon} size={16} color={"currentColor"} />
        </Link>
      );
    };

    const email = "help@klgtu.ru";

    return (
      <div className={styles.links}>
        <ButtonLink url={`mailto:${email}`} icon={"email"}>
          {email}
        </ButtonLink>
        <ButtonLink
          url={
            "https://klgtu.ru/divisions/tsentr_informatsionnykh_tekhnologiy/"
          }
          icon={"site"}
          solid={true}
        >
          ЦИТ КГТУ
        </ButtonLink>
      </div>
    );
  };

  return (
    <Modal
      title={"Команда разработчиков"}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className={styles.container}>
        <Developers />
        <Links />
      </div>
    </Modal>
  );
};

export default DevTeamModal;
