import styles from "./HowAdmissionWork.module.css";
import Modal from "../../components/Modal/Modal";
import SVGIcon from "../../components/SVGIcon";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, m, motion } from "framer-motion";
/**
 * Компонент модального окна с командой разработчиков.
 *
 * @param {Object} props Свойства компонента.
 * @param {boolean} props.isVisible Флаг, указывающий на показ окна.
 * @param {Function} props.onClose Обратный вызов, вызываемый при нажатии кнопки закрытия окна.
 * @return {JSX.Element} React-элемент модального окна.
 */

const HowAdmissionWork = ({ isVisible, onClose }) => {
  const [studyForm, setStudyForm] = useState({
    bachelorsDegree: true,
    mastersDegree: false,
  });

  const bachelorsButtonRef = useRef(null);
  const mastersButtonRef = useRef(null);

  function handleClick(type) {
    return () => {
      const typeForClose =
        type === "bachelorsDegree" ? "mastersDegree" : "bachelorsDegree";
      setStudyForm({
        [type]: true,
        [typeForClose]: false,
      });
    };
  }

  function scrollIntoView() {
    if (studyForm.mastersDegree === true) {
      document.querySelector("#modal").scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (studyForm.mastersDegree === true) {
  //       document.querySelector("#modal").scrollTo({
  //         top: 0,
  //         behavior: "smooth",
  //       });
  //     }
  //   }, 300);
  // }, [studyForm.mastersDegree]);

  return (
    <Modal
      title={"Как проходит зачисление?"}
      isVisible={isVisible}
      onClose={onClose}
    >
      <motion.div layout="position" className={styles.container}>
        <motion.button
          ref={bachelorsButtonRef}
          layout="position"
          className={`${styles.titleButton}`}
          onClick={handleClick("bachelorsDegree")}
          style={
            studyForm.bachelorsDegree === true ? { cursor: "default" } : {}
          }
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          <SVGIcon name="graduation-hat-alt" />
          Бакалавриат/специалитет
          {studyForm.bachelorsDegree === false && (
            // (
            //   <SVGIcon name="chevron-up" />
            // )
            <SVGIcon name="chevron-down" />
          )}
        </motion.button>
        <AnimatePresence mode="popLayout">
          {studyForm.bachelorsDegree === true && (
            <motion.ul
              initial={
                // studyForm.bachelorsDegree === false
                //   ? { opacity: 1, height: "auto" }
                //   :
                { opacity: 0 }
              }
              animate={
                // studyForm.bachelorsDegree === false
                //   ? { opacity: 0, height: 0 }
                //   :
                { opacity: 1 }
              }
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
                type: "keyframes",
                ease: "linear",
              }}
              // onAnimationEndCapture={scrollIntoView}
              className={
                studyForm.bachelorsDegree === true ? styles.textVisible : ""
              }
              // style={{ position: "relative" }}
            >
              <li>
                Этап приоритетного зачисления:
                <ul>
                  <li>
                    28 июля в 11:00 по Калининградскому времени завершается
                    приём оригиналов документов об образовании для поступающих
                    без вступительных испытаний, на места в пределах особой и
                    отдельной квот и в рамках целевого приёма;
                  </li>
                  <li>30 июля издаются приказы о зачислении.</li>
                </ul>
              </li>
              <li>
                Основной этап зачисления, заполнение 100% конкурсных мест:
                <ul>
                  <li>
                    3 августа в 11:00 по Калининградскому времени завершается
                    приём оригиналов документов установленного образца от лиц,
                    подлежащих зачислению на этом этапе;
                  </li>
                  <li>9 августа издаются приказы о зачислении.</li>
                </ul>
              </li>
              <li>
                Зачисление по договорам об оказании платных образовательных
                услуг:
                <ul>
                  <li>
                    28 августа завершается заключение договоров об оказании
                    платных образовательных услуг для очной и очно-заочной формы
                    обучения и 8 сентября для заочной.
                  </li>
                  <li>не позднее 30 августа - издаются приказы о зачислении</li>
                </ul>
              </li>
            </motion.ul>
          )}
        </AnimatePresence>
        <motion.button
          ref={mastersButtonRef}
          layout="position"
          transition={{ duration: 0.3, delay: 0.1 }}
          className={`${styles.titleButton}`}
          onClick={handleClick("mastersDegree")}
          style={studyForm.mastersDegree === true ? { cursor: "default" } : {}}
        >
          <SVGIcon name="graduation-hat-alt" /> Магистратура
          {studyForm.mastersDegree === false && (
            // (
            //   <SVGIcon name="chevron-up" />
            // )
            <SVGIcon name="chevron-down" />
          )}
        </motion.button>
        <AnimatePresence mode="popLayout">
          {studyForm.mastersDegree === true && (
            <motion.ul
              className={
                studyForm.mastersDegree === true ? styles.textVisible : ""
              }
              initial={
                // studyForm.mastersDegree === false
                //   ? { opacity: 1, height: "auto" }
                // :
                { opacity: 0, y: 1000 }
              }
              animate={
                // studyForm.mastersDegree === false
                //   ? { opacity: 0, height: 0 }
                //   :
                { opacity: 1, y: 0 }
              }
              onAnimationStart={scrollIntoView}
              exit={{ opacity: 0, y: 1000 }}
              transition={{
                duration: 0.3,
                opacity: { duration: 0.3 },
                delay: 0.1,
                type: "keyframes",
                ease: "linear",
              }}
              // style={{ position: "relative" }}
            >
              <li>
                Этап приоритетного зачисления:
                <ul>
                  <li>
                    15 августа в 11:00 по Калининградскому времени завершается
                    приём оригиналов документов об образовании для поступающих
                    без вступительных испытаний, на места в пределах особой и
                    отдельной квот и в рамках целевого приёма;
                  </li>
                  <li>16 августа издаются приказы о зачислении.</li>
                </ul>
              </li>
              <li>
                Основной этап зачисления, заполнение 100% конкурсных мест:
                <ul>
                  <li>
                    18 августа в 11:00 по Калининградскому времени завершается
                    приём оригиналов документов установленного образца от лиц,
                    подлежащих зачислению на этом этапе;
                  </li>
                  <li>19 августа издаются приказы о зачислении.</li>
                </ul>
              </li>
              <li>
                Зачисление по договорам об оказании платных образовательных
                услуг:
                <ul>
                  <li>
                    28 августа завершается заключение договоров об оказании
                    платных образовательных услуг для очной формы обучения и 9
                    сентября для заочной.
                  </li>
                  <li>
                    не позднее 30 августа - издаются приказы о зачислении для
                    очной формы и 10 сентября для заочной.
                  </li>
                </ul>
              </li>
            </motion.ul>
          )}
        </AnimatePresence>
      </motion.div>
    </Modal>
  );
};

export default HowAdmissionWork;
