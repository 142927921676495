import { useEffect, useRef, useState } from "react";
import SVGIcon from "../../../components/SVGIcon";
import styles from "./SearchInput.module.css";
import useKeyDown from "../../../utils/useKeyDown";

/**
 * Компонент отражающий поле ввода для поиска.
 *
 * @param {Object} props Свойства компонента.
 * @param {string} [props.description=''] Заголовок поиска.
 * @param {string} props.placeholder Описание данных, которые необходимо ввести
 * @param {string} props.inputValue Вводимое в поле значение
 * @param {Function} props.handleChange Функция для обработки вводимого значения
 * @param {Function} props.clearInputValue Функция для очищения поля поиска
 * @param {boolean} [props.isDisabled=false] Флаг для блокировки поиска
 * @param {boolean} [props.autoFocus=true] Флаг для выставления авто=фокуса для инпута
 * @param {Function} [props.openList=() => {}] Функция для открытия следующего селектора
 * @param {Function} [props.onBlure=() => {}] Функция, срабатывающая при снятии фокуса с инпута
 * @param {Function} [props.onFocus=() => {}] Функция, срабатывающая при фокусировки инпута
 * @param {boolean} [props.isSnilsSearch=false] Флаг использования инпута для поиска по СНИЛС/УКП
 * @param {boolean} [props.isfocusStyle=false] Флаг использования стиля при фокусе для инпута
 * @param {Object} [props.errorMessage={title: '', isVisible: false}] Обект сообщения об ошибке
 * @param {boolean} [props.isKeyActivated=false] Флаг для активации перемещения по списку с помощью клавиатуры
 * @param {boolean} setIsKeyActivated Функция для включения\выключения возможности перемещения по списку с помощью клавиатуры
 * @return {JSX.Element} React-элемент блока c таблицей поступающих.
 */

export default function SearchInput({
  description = "",
  placeholder,
  inputValue,
  handleChange,
  clearInputValue,
  isDisabled = false,
  autoFocus = true,
  openList = () => {},
  onBlure = () => {},
  onFocus = () => {},
  isSnilsSearch = false,
  isfocusStyle = false,
  errorMessage = { title: "", isVisible: false },
  isKeyActivated = false,
  setIsKeyActivated = () => {},
}) {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [inputCursorPos, setInputCursorPos] = useState(0);

  const inputRef = useRef(null);

  const { handleKeyDown } = useKeyDown({ isKeyActivated, setIsKeyActivated });
  useKeyDown({ isKeyActivated, setIsKeyActivated });

  const setInputFocus = () => {
    if (inputRef?.current) {
      inputRef.current.focus({
        preventScroll: true,
        focusVisible: false,
      });
      setIsKeyActivated(false);
      setIsInputFocused(true);
    }
    if (!isDisabled) {
      openList();
    }
  };

  //   Закрытие списка при нажатии вне его области

  function onCloseList(e) {
    if (
      inputRef?.current &&
      document.querySelector(":focus") === inputRef?.current &&
      !inputRef?.current.contains(e?.target) &&
      !inputRef?.current.parentNode.contains(e?.target)
    ) {
      setIsInputFocused(false);
      onBlure();
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", onCloseList);
    return () => document.removeEventListener("mousedown", onCloseList);
  }, []);

  /**
   * Компонент для отображения кнопки очистки поля ввода.
   *
   * @return {JSX.Element} React-элемент кнопки
   */
  const ClearButton = () => {
    const className =
      inputValue?.length > 0 && isInputFocused
        ? styles.clearButton
        : styles.hidden;

    const handleClick = () => {
      clearInputValue();
      setInputFocus();
    };

    return (
      <button className={className} onClick={handleClick}>
        <SVGIcon name={"circle-xmark"} size={16} />
      </button>
    );
  };

  function onChangeValue(e) {
    handleChange(e);
    setInputFocus();
    if (isSnilsSearch) {
      if (inputValue.length < 10) {
        if (e.target.selectionStart > 9) {
          setInputCursorPos(e.target.selectionStart + 3);
        } else if (e.target.selectionStart > 11) {
          setInputCursorPos(11 + 3);
        } else {
          setInputCursorPos(e.target.selectionStart);
        }
      } else {
        if (
          e.target.selectionStart === 4 ||
          e.target.selectionStart === 8 ||
          e.target.selectionStart === 12
        ) {
          setInputCursorPos(e.target.selectionStart + 1);
        } else {
          setInputCursorPos(e.target.selectionStart);
        }
      }
    } else {
      setInputCursorPos(e.target.selectionStart);
    }
  }

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current.setSelectionRange(inputCursorPos, inputCursorPos);
    }
  }, [inputCursorPos]);

  return (
    <div className={styles.container} onClick={setInputFocus} onFocus={onFocus}>
      {description && <p className={styles.description}>{description}</p>}
      <div
        className={
          isDisabled
            ? "inputGroupDisabled"
            : isfocusStyle
            ? "inputGroup inputGroupFocus"
            : "inputGroup"
        }
      >
        <SVGIcon
          name={"magnifying-glass"}
          className={inputValue ? "inputIconTyped" : "inputIcon"}
          size={18}
        />
        <input
          id="searchInput"
          ref={inputRef}
          value={inputValue}
          onChange={onChangeValue}
          type="text"
          className="input"
          onKeyDown={handleKeyDown}
          inputMode={isSnilsSearch ? "numeric" : "search"}
          placeholder={placeholder}
          disabled={isDisabled}
          autoFocus={autoFocus}
          onFocus={onFocus}
          autoComplete="off"
        />
        {errorMessage.isVisible && isInputFocused && (
          <p className={styles.searchTooltip}>{errorMessage.title}</p>
        )}
        <ClearButton />
      </div>
    </div>
  );
}
