import styles from "./InputValueItem.module.css";

/**
 * Компонент области со значением.
 *
 * @param {Object} props Свойства компонента.
 * @param {string} props.value Значение области.
 * @param {boolean} props.isDisabled Флаг для блокировки области со значением.
 * @return {JSX.Element} React-элемент области со значением.
 */

export default function InputValueItem({ isDisabled = false, value }) {
  return (
    <div className={isDisabled ? styles.itemDisabled : styles.item}>
      {value}
    </div>
  );
}
