import SVGIcon from "../../SVGIcon";
import styles from "./StatusButton.module.css";

/**
 * Компонент статус-кнопки.
 *
 * @param {Object} props Свойства компонента.
 * @param {1 | 2 | 3} props.type Тип статус-кнопки
 * @param {Function} props.onClickList Функция для открытия списка по клику на кнопку
 * @param {boolean} props.isHover Флаг для выбора отображения доп информации при наведении на кнопку
 * @return {JSX.Element} React-элемент статус-кнопки.
 */

export default function StatusButton({ type, onClickList, isHover }) {
  function handleClick(e) {
    onClickList(e);
  }

  return (
    <button
      className={`${styles.statusButton} ${
        type === 2
          ? "successStatusButton"
          : type === 3
          ? "errorStatusButton"
          : "defaultStatusButton"
      }`}
      onMouseMove={isHover ? handleClick : () => {}}
      onClick={isHover ? () => {} : handleClick}
    >
      <SVGIcon
        name={type === 2 ? "check" : type === 3 ? "x-close" : "clock"}
        size={20}
      />
    </button>
  );
}
