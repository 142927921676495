import SnilsInput from "../ui/SnilsInput/SnilsInput";
import { useEffect, useState } from "react";
import formatCode from "../../utils/formatCode";
import { useAppNav } from "../../utils/useAppNav";

/**
 * Компонент для отображения поиска по СНИЛС/УКП для подвала страницы.
 *
 * @return {JSX.Element} React-элемент поиска.
 */

export default function SearchBySnils() {
  const [searchValue, setSearchValue] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const { navToSnilsSearchResults } = useAppNav();

  useEffect(() => {
    if (showErrorMessage) {
      const timeError = setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);

      return () => clearTimeout(timeError);
    }
  }, [showErrorMessage]);

  function onClickSearch() {
    const currentSearchValue = searchValue.replace(/[^\d]/g, "");
    if (currentSearchValue.length === 9 || currentSearchValue.length === 11) {
      navToSnilsSearchResults({ code: currentSearchValue });
    } else {
      setShowErrorMessage(true);
    }
  }

  function onChangeValue(e) {
    setShowErrorMessage(false);
    let formattedCode = formatCode(e.target.value);
    setSearchValue(formattedCode);
  }

  return (
    <SnilsInput
      inputValue={searchValue}
      onChange={onChangeValue}
      onClick={onClickSearch}
      showErrorMessage={showErrorMessage}
    />
  );
}
