import React from "react";
import { Sheet } from "react-modal-sheet";

/**
 * Компонент выдвижной шторки снизу.
 *
 * @param {Object} props Свойства компонента.
 * @param {React.ReactNode} props.content Содержимое шторки.
 * @param {React.ReactNode} props.titleBlock Блок с заголовком шторки.
 * @param {React.string} props.maxHeight Содержимое шторки.
 * @param {boolean} props.isOpen Флаг, указывающий на открытие шторки.
 * @param {Function} props.onClose Обратный вызов, вызываемый при закрытии шторки.
 * @returns {Element} Компонент шторки.
 * @constructor
 */
const BottomSheet = ({
  children: content,
  titleBlock,
  maxHeight,
  isOpen,
  onClose,
}) => {
  return (
    <Sheet isOpen={isOpen} detent={"content-height"} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          {titleBlock && titleBlock}
          <Sheet.Scroller>
            <div style={{ maxHeight: maxHeight }}>{content}</div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  );
};

export default BottomSheet;
