import SearchInput from "../ui/SearchInput/SearchInput";
import { useEffect, useRef, useState } from "react";
import styles from "./ApplicantsTableBlock.module.css";
import ListsVariantsSelect from "../ui/ListsVariantsSelect/ListsVariantsSelect";
import useApi from "../../utils/useApi";
import Spinner from "../ui/Spinner/Spinner";
import CountItem from "../ui/CountItem/CountItem";
import ApplicantsTable from "../ApplicantsTable/ApplicantsTable";
import formatCode from "../../utils/formatCode";
import { useAppContext } from "../../App";
import SVGIcon from "../SVGIcon";
import MessageBlock from "../ui/MessageBlock/MessageBlock";

/**
 * Компонент отражающий блок c таблицей поступающих.
 *
 * @param {Object} props Свойства компонента.
 * @param {object} props.state Состояние страницы списков поступающих.
 * @param {object} props.urlParams Состояние c параметрами url страницы.
 * @param {Function} props.setUrlParams Функция для обновления состояния параметров url страницы без перезаписи url.
 * @param {Function} props.setSearchParams Функция для обновления состояния параметров url страницы c перезаписью url.
 * @param {Function} props.setState Функция для обновления состояния страницы списков поступающих.
 * @param {Function} props.isLoading Состояние загрузки страницы списков поступающих.
 * @param {Function} props.setIsLoading Функция для обновления состояния загрузки страницы списков поступающих.
 * @return {JSX.Element} React-элемент блока c таблицей поступающих.
 */

export default function ApplicantsTableBlock({
  state,
  urlParams,
  setUrlParams,
  setSearchParams,
  setState,
  isLoading,
  setIsLoading,
}) {
  const { setMoreInfoModalData, pagesState, setPagesState } = useAppContext();
  const [stateTableBlock, setStateTableBlock] = useState({
    inputValue: "",
    noMatchesFound: {
      visible: false,
      notFound: false,
    },
    variantsLists: {
      list: [],
      selectedValue: { id: 4, name: "" },
    },
    selectedCategoryID: {
      index: 0,
      isSearchParam: false, // флаг для отслеживания, задана ли категория в параметрах url
    },
    // флаг для отслеживания наличия искомого СНИЛС/УКП в таблицах каждой категории
    hasInputValue: state?.categories
      ? state?.categories.map((item) => {
          return { [item.title]: false };
        })
      : null,
  });

  const { getRequest } = useApi();

  const categoriesRef = useRef(null);

  const [screenWidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );

  const [screenHeight, setScreenHeight] = useState(
    document.documentElement.clientHeight
  );

  useEffect(() => {
    let categoryIndex = 0;
    state?.categories?.forEach((item, index) => {
      if (item.uid === pagesState?.category) {
        categoryIndex = index;
      }
    });

    setStateTableBlock((prev) => ({
      ...prev,
      inputValue: pagesState?.code ? formatCode(pagesState?.code) : "",
      selectedCategoryID: {
        ...prev.selectedCategoryID,
        index: categoryIndex,
      },
    }));

    setPagesState({
      code: "",
      category: "",
    });

    setVariantsValue(state);
  }, []);

  useEffect(() => {
    const getScreenWidth = () => {
      setScreenWidth(document.documentElement.clientWidth);
      setScreenHeight(document.documentElement.clientHeight);
    };

    window.addEventListener("resize", getScreenWidth);
    return () => {
      window.removeEventListener("resize", getScreenWidth);
    };
  }, []);

  // Слушаем изменение URL-параметров и обновляем состояние выбранной категории и поиска
  useEffect(() => {
    let variant = urlParams?.variant ?? "";

    if (
      variant &&
      Number(variant) !== stateTableBlock?.variantsLists?.selectedValue?.id
    ) {
      const hasVariantInState =
        state?.variants?.filter((item) => item.id === Number(variant))
          .length !== 0;
      if (hasVariantInState) {
        changeVariants(Number(variant));
      }
    } else if (!variant && pagesState?.requestParams?.variant) {
      changeVariants("");
    } else {
      updateVariants();
    }
  }, [urlParams]);

  // При вводе в поиск кода (СНИЛ/УКП) обновляем состояние поиска, состояние наличия кода в таблицах каждой категории и состояния выбранной категории
  useEffect(() => {
    setStateTableBlock((prev) => ({
      ...prev,
      noMatchesFound: {
        visible: false,
        notFound: false,
      },
    }));
    const unFormattedCode = stateTableBlock.inputValue.replace(/[^\d]/g, "");
    if (
      stateTableBlock.inputValue &&
      (unFormattedCode.length === 9 || unFormattedCode.length === 11)
    ) {
      let hasCode = false;

      const newHasInputValue = state?.categories.map((item) => {
        const hasInGroupArr = item?.competitionGroups.filter((group) => {
          const hasInTableArr = group?.table.filter(
            (row) => row.code === unFormattedCode
          );
          if (hasInTableArr.length > 0) return true;
          return false;
        });
        if (hasInGroupArr.length > 0) {
          hasCode = true;
          return { [item.title]: true };
        }
        return { [item.title]: false };
      });
      setStateTableBlock((prev) => ({
        ...prev,
        hasInputValue: newHasInputValue,
      }));

      let isCategorySet = false;

      if (!hasCode) {
        setStateTableBlock((prev) => ({
          ...prev,
          noMatchesFound: {
            visible: true,
            notFound: true,
          },
        }));
      }

      if (
        newHasInputValue?.[stateTableBlock.selectedCategoryID.index]?.[
          state?.categories?.[stateTableBlock.selectedCategoryID.index]?.title
        ] === false
      ) {
        state?.categories.forEach((element, index) => {
          if (
            newHasInputValue[index][element.title] === true &&
            isCategorySet === false &&
            stateTableBlock.selectedCategoryID.isSearchParam === false
          ) {
            isCategorySet = true;
            setStateTableBlock((prev) => ({
              ...prev,
              selectedCategoryID: {
                ...prev.selectedCategoryID,
                index: index,
              },
            }));
            return;
          }
          return;
        });
      }
    } else {
      setStateTableBlock((prev) => ({
        ...prev,
        hasInputValue:
          state?.categories?.map((item) => {
            return { [item.title]: false };
          }) || null,
      }));
    }
    setStateTableBlock((prev) => ({
      ...prev,
      selectedCategoryID: {
        ...prev.selectedCategoryID,
        isSearchParam: false,
      },
    }));
  }, [stateTableBlock.inputValue, isLoading.table, state?.categories]);

  useEffect(() => {
    updateVariants();
  }, [state, setMoreInfoModalData]);

  useEffect(() => {
    if (categoriesRef?.current) {
      const categoryWidth =
        categoriesRef?.current?.childNodes?.[
          stateTableBlock.selectedCategoryID.index
        ].clientWidth;
      const categoriesContainerWidth = categoriesRef.current.clientWidth;
      if (stateTableBlock.selectedCategoryID.index === 0) {
        categoriesRef.current.scrollTo({
          left: 0,
          top: 0,
          behavior: "smooth",
        });
      }
      if (stateTableBlock.selectedCategoryID.index !== 0) {
        categoriesRef?.current?.scrollTo({
          left:
            stateTableBlock.selectedCategoryID.index * categoryWidth -
            (categoriesContainerWidth - categoryWidth) / 2,
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }, [stateTableBlock.selectedCategoryID.index]);

  function closeNoMatchesFound() {
    setStateTableBlock((prev) => ({
      ...prev,
      noMatchesFound: {
        ...prev.noMatchesFound,
        visible: false,
      },
    }));
  }

  function handleChangeSnilsValue(e) {
    let formattedCode = formatCode(e.target.value);
    setStateTableBlock((prev) => ({
      ...prev,
      inputValue: formattedCode,
    }));
  }

  function clearSnilsValue() {
    closeNoMatchesFound();
    setStateTableBlock((prev) => ({
      ...prev,
      inputValue: "",
    }));
  }

  // обновляем состояние вариантов списков

  function updateVariants() {
    setVariantsValue(state);

    // если данные обновились для контракта, то категориия там одна и надо вернуться к начальному состоянию
    if (state?.categories?.length === 1) {
      setStateTableBlock((prev) => ({
        ...prev,
        selectedCategoryID: {
          ...prev.selectedCategoryID,
          index: 0,
        },
        hasInputValue: state?.categories.map((item) => {
          return { [item.title]: false };
        }),
      }));
    }
  }

  function setVariantsValue(resData) {
    if (resData?.variants?.length > 0) {
      setStateTableBlock((prev) => ({
        ...prev,
        variantsLists: {
          list: resData.variants.filter((item) => item.isSelected === false),
          selectedValue: resData.variants.filter(
            (item) => item.isSelected === true
          )[0],
        },
      }));

      setMoreInfoModalData(resData?.variants);
    }
  }

  function changeVariants(id, updateSearchParams = false) {
    setIsLoading((prev) => ({
      ...prev,
      table: true,
    }));

    const filterWithoutVariant = {};

    for (let i in state?.filter) {
      if (i !== "variant") {
        filterWithoutVariant[i] = state?.filter[i];
      }
    }

    let newParams = {
      ...filterWithoutVariant,
      ...(id ? { variant: id } : ""),
    };

    setPagesState((prev) => ({
      ...prev,
      requestParams: newParams,
    }));

    getRequest("/applicants-lists/", newParams)
      .then((res) => {
        if (res?.result) {
          const resData = res.result;
          setState(resData);
          setVariantsValue(resData);

          const newSearchParams = {
            ...urlParams,
            ...(id ? { variant: id.toString() } : ""),
          };
          if (updateSearchParams) {
            setUrlParams((prev) => ({ ...prev, ...newSearchParams }));
            setSearchParams(newSearchParams);
          }
        } else {
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading((prev) => ({
          ...prev,
          table: false,
        }));
      });
  }

  function onClickListsVariantsItem(id) {
    changeVariants(id, true);
  }

  function handleChangeCategory(id) {
    return () => {
      setStateTableBlock((prev) => ({
        ...prev,
        selectedCategoryID: {
          ...prev.selectedCategoryID,
          index: id,
        },
      }));
    };
  }

  function showNoMatchesFound() {
    if (stateTableBlock.noMatchesFound.notFound) {
      setStateTableBlock((prev) => ({
        ...prev,
        noMatchesFound: {
          ...prev.noMatchesFound,
          visible: true,
        },
      }));
    }
  }

  return (
    <div className={styles.container} id="applicant-table-block">
      {state?.messages?.length > 0 && (
        <div className={styles.messagesContainer}>
          <MessageBlock msgList={state?.messages} />
        </div>
      )}

      {state?.categories?.length > 0 && (
        <>
          <h2 className={styles.description}>Списки поступающих</h2>
          <div className={styles.optionsGroup}>
            <div className={styles.inputContainer}>
              <SearchInput
                description={""}
                placeholder={"Поиск по СНИЛС/УКП"}
                inputValue={stateTableBlock.inputValue}
                handleChange={handleChangeSnilsValue}
                clearInputValue={clearSnilsValue}
                onBlure={closeNoMatchesFound}
                openList={showNoMatchesFound}
                isSnilsSearch
                autoFocus={screenWidth > 1024 ? true : false}
                errorMessage={{
                  title: "Совпадений не найдено",
                  isVisible:
                    stateTableBlock?.noMatchesFound?.visible &&
                    !isLoading?.table,
                }}
              />
            </div>
            {isLoading.placesInfoBlock ? (
              <div />
            ) : (
              <ListsVariantsSelect
                list={stateTableBlock.variantsLists.list}
                value={stateTableBlock.variantsLists.selectedValue}
                onClickItem={onClickListsVariantsItem}
                count={state?.info?.totalCount}
              />
            )}
          </div>
        </>
      )}
      {state?.categories?.length > 1 && !isLoading.table && (
        <div
          ref={categoriesRef}
          className={styles.categoriesContainer}
          style={{
            gridTemplateColumns: `repeat(${state?.categories.length}, 1fr)`,
          }}
        >
          {state?.categories.map((item, index) => (
            <div
              key={index}
              onClick={handleChangeCategory(index)}
              className={`${styles.category} ${
                stateTableBlock.selectedCategoryID.index === index
                  ? styles.categorySelected
                  : ""
              }`}
            >
              {(stateTableBlock.inputValue?.replace(/[^\d]/g, "").length ===
                9 ||
                stateTableBlock.inputValue?.replace(/[^\d]/g, "").length ===
                  11) &&
                stateTableBlock.hasInputValue?.[index]?.[item.title] && (
                  <SVGIcon name={"check-fat"} size={20} />
                )}
              {item?.title}
              <CountItem
                isCountSmall
                isSelected={
                  stateTableBlock.selectedCategoryID.index === index
                    ? true
                    : false
                }
              >
                {item.count}
              </CountItem>
            </div>
          ))}
        </div>
      )}
      <div
        className={`${styles.tableContainer} ${
          isLoading.table ? styles.center : ""
        }`}
      >
        {isLoading.table ? (
          <Spinner />
        ) : (
          <div className={styles.tablesWrap}>
            {state?.categories?.[stateTableBlock.selectedCategoryID?.index]
              ?.competitionGroups?.length !== 0 ? (
              state?.categories?.[
                stateTableBlock.selectedCategoryID?.index
              ]?.competitionGroups.map((item, index) => (
                <div key={index} className={styles.tableItem}>
                  {state?.categories[stateTableBlock.selectedCategoryID.index]
                    ?.competitionGroups?.length > 1 && (
                    <h1 className={styles.tableTitle}>{item.title}</h1>
                  )}
                  <ApplicantsTable
                    isMultipleTables={
                      state?.categories[
                        stateTableBlock.selectedCategoryID.index
                      ]?.competitionGroups.length > 1
                        ? true
                        : false
                    }
                    isVisibleCategories={state?.categories?.length > 1}
                    key={index}
                    data={item.table}
                    additionalColumns={state?.additionalColumns}
                    searchValue={stateTableBlock.inputValue}
                    selectedCategoryID={
                      stateTableBlock.selectedCategoryID.index
                    }
                    screenWidth={screenWidth}
                    screenHeight={screenHeight}
                  />
                </div>
              ))
            ) : (
              <ApplicantsTable
                data={[]}
                isMultipleTables={false}
                additionalColumns={[]}
                isVisibleCategories={state?.categories?.length > 1}
                screenWidth={screenWidth}
                screenHeight={screenHeight}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
