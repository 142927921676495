import React, { useEffect, useState } from "react";
import useTitle from "../../utils/useTitle";
import OrdersSearchGroup from "../../components/OrdersSearchGroup/OrdersSearchGroup";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import styles from "./OrdersPage.module.css";
import UpButton from "../../components/ui/UpButton/UpButton";

/**
 * Компонент страницы поиска.
 *
 * @return {JSX.Element} React-элемент страницы.
 */

const OrdersPage = () => {
  const [searchGroupBottom, setSearchGroupBottom] = useState(0);

  const [screenWidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );

  const [upButtonBottom, setUpButtonBottom] = useState("130px");

  useTitle("Приказы");

  function getSearchGroupSize() {
    const rectSearchGroup = document
      ?.querySelector("#order-search-group")
      ?.getBoundingClientRect();

    setSearchGroupBottom(rectSearchGroup?.bottom);
  }

  useEffect(() => {
    document.addEventListener("scroll", getSearchGroupSize);
    return () => document.removeEventListener("scroll", getSearchGroupSize);
  }, []);

  function onScrollTop() {
    document?.documentElement?.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    const getScreenWidth = () => {
      const newScreenSize = document.documentElement.clientWidth;
      setScreenWidth(newScreenSize);
      const rectFooter = document
        .querySelector("footer")
        .getBoundingClientRect();
      const newUpButtonBottom =
        newScreenSize < 1024
          ? `${rectFooter.height + 50}px`
          : `${rectFooter.height + 40}px`;

      setUpButtonBottom(newUpButtonBottom);
    };

    window.addEventListener("resize", () => {
      getScreenWidth();
    });
    return () => {
      window.removeEventListener("resize", () => {
        getScreenWidth();
      });
    };
  }, []);

  return (
    <>
      <Header isOrdersSearch isBackButton />
      <main className={styles.main}>
        <OrdersSearchGroup />
      </main>
      {searchGroupBottom < 0 && (
        <div
          className={styles.upButtonContainer}
          style={
            screenWidth < 1024
              ? { bottom: `${upButtonBottom}` }
              : { bottom: `${upButtonBottom}` }
          }
        >
          {screenWidth > 640 && <UpButton onClick={onScrollTop} />}
        </div>
      )}
      <Footer logo isNavToOrders={false} />
    </>
  );
};
export default OrdersPage;
