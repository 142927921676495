import styles from "./Modal.module.css";
import SVGIcon from "../SVGIcon";
import React, { useEffect, useState } from "react";

/**
 * Компонент для отображения модального окна.
 *
 * @param {Object} props Свойства компонента.
 * @param {React.ReactNode} props.content Содержимое окна.
 * @param {string} props.title Текст заголовка окна.
 * @param {boolean} props.isVisible Флаг, указывающий на показ окна.
 * @param {Function} props.onClose Обратный вызов, вызываемый при нажатии кнопки закрытия окна.
 * @param {boolean} props.isSmallModal Флаг, указывающий на уменьшенную ширину модального окна.
 * @return {Element} Элемент модального окна.
 */
const Modal = ({
  children: content,
  title,
  isVisible,
  onClose,
  isSmallModal = false,
}) => {
  const [isCloseAnimation, setIsCloseAnimation] = useState(!isVisible);

  useEffect(() => {
    const root = document.querySelector(":root");
    root.style.overflowY = isVisible ? "hidden" : "auto";

    return () => {
      root.style.overflowY = "auto";
    };
  }, [isVisible]);

  const Header = () => {
    const Title = () => <div className={styles.title}>{title}</div>;
    const CloseButton = () => {
      return (
        <button className={styles.closeButton} onClick={handleClick}>
          <SVGIcon name={"x-close"} size={18} />
        </button>
      );
    };

    return (
      <div className={styles.header}>
        <Title />
        <CloseButton />
      </div>
    );
  };

  const handleClick = () => {
    setIsCloseAnimation(true);
    setTimeout(() => {
      onClose();
    }, 500);
  };
  const handleClickOutside = (e) => {
    if (e.target.classList.contains(styles.overlay)) {
      handleClick();
    }
  };

  return (
    <div
      className={`${styles.overlay} 
          ${!isCloseAnimation ? styles.open : ""}
          `}
      onClick={handleClickOutside}
    >
      <div
        className={`${styles.container} ${
          isSmallModal ? styles.smallModal : ""
        }`}
      >
        <Header />
        <div className={styles.body} id="modal">
          {content}
        </div>
      </div>
    </div>
  );
};

export default Modal;
