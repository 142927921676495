import React from "react";
import styles from "./Logo.module.css";
import Link from "../Link";

/**
 * Компонент для отображения логотипа.
 *
 * @return {JSX.Element} Возвращает JSX-элемент логотипа.
 */
const Logo = () => {
  return (
    <Link url="/">
      <div className={styles.logo} />
    </Link>
  );
};

export default Logo;
