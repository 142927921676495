import styles from "./MoreInfo.module.css";
import Modal from "../../components/Modal/Modal";
/**
 * Компонент модального окна с командой разработчиков.
 *
 * @param {Object} props Свойства компонента.
 * @param {boolean} props.isVisible Флаг, указывающий на показ окна.
 * @param {Function} props.onClose Обратный вызов, вызываемый при нажатии кнопки закрытия окна.
 * @return {JSX.Element} React-элемент модального окна.
 */
const MoreInfo = ({ isVisible, onClose, data }) => {
  return (
    <Modal
      title={"Подробнее о вариантах списков"}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className={styles.container}>
        {data &&
          data.map((item, index) => (
            <div key={index} className={styles.block}>
              <h2 className={styles.title}>{item?.name.toUpperCase()}</h2>
              <p className={styles.description}>{item?.description}</p>
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default MoreInfo;
