import axios from "axios";

const testUrl = "https://api.klgtu.ru/abitur/test/v1";
const mainUrl = "https://api.klgtu.ru/abitur/v1";

export default function useApi() {
  let source = null;

  /**
   * Отправляет HTTP-запрос на сервер.
   *
   * @param {string} path Путь к конечной точке API
   */

  async function getRequest(path, params = {}) {
    cancelSource();

    source = axios.CancelToken.source();

    return await axios
      .get(`${mainUrl}${path}`, {
        headers: {
          "Content-Type": "application/json",
        },
        ...(params && { params: params }),
        cancelToken: source.token,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          const errorData = error.response.data.error;
          const description = errorData
            ? `Ошибка ${errorData.code}: ${errorData.message}`
            : "Произошла непредвиденная ошибка";

          return description;
        }
        return error.message;
      });
  }

  function cancelSource() {
    source?.cancel();
  }

  return { getRequest, cancelSource };
}
