import styles from "./Contacts.module.css";
import Modal from "../../components/Modal/Modal";
import SVGIcon from "../../components/SVGIcon";
import Link from "../../components/ui/Link";

/**
 * Компонент модального окна с командой разработчиков.
 *
 * @param {Object} props Свойства компонента.
 * @param {boolean} props.isVisible Флаг, указывающий на показ окна.
 * @param {Function} props.onClose Обратный вызов, вызываемый при нажатии кнопки закрытия окна.
 * @return {JSX.Element} React-элемент модального окна.
 */
const Contacts = ({ isVisible, onClose }) => {
  return (
    <Modal
      title={"Контакты приёмной комиссии"}
      isVisible={isVisible}
      onClose={onClose}
      isSmallModal
    >
      <div className={styles.container}>
        <div className={styles.description}>
          <Link
            url={"https://klgtu.ru/abitur/"}
            newTab
            className={styles.titleDescription}
          >
            Приёмная комиссия <SVGIcon size={20} name="arrow-up-right-thin" />
          </Link>
          <Link url="tel:84012995999">8 (4012) 99-59-99</Link>
          <Link url="mailto:pk@klgtu.ru">pk@klgtu.ru</Link>
        </div>
        <div className={styles.linksContainer}>
          <Link url={"https://vk.com/klgtu_cpp"} newTab className={styles.link}>
            <SVGIcon size={32} name="vk" />
          </Link>
          <Link
            url={"https://t.me/selectioncommitteeKLGTU"}
            newTab
            className={styles.link}
          >
            <SVGIcon size={32} name="telegram-blue" />
          </Link>
          <Link
            url={"https://wa.me/79097827011"}
            newTab
            className={styles.link}
          >
            <SVGIcon size={32} name="whatsapp" />
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default Contacts;
