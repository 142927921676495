import { createSearchParams, useNavigate } from "react-router-dom";

/**
 * Хук для осуществления навигации по страницам приложения.
 */
export const useAppNav = () => {
  const navigate = useNavigate();

  /**
   * Выполняет переход на страницу поиска расписания.
   */
  const navToSearch = () => {
    navigate({
      pathname: "/",
    });
  };

  /**
   * Выполняет переход на страницу списков поступающих.
   *
   * @param {object} params Поисковые параметры.
   */
  const navToApplicantsLists = (params) => {
    const searchParams = createSearchParams(params).toString();

    navigate({
      pathname: "/applicants-lists",
      search: searchParams,
    });
  };

  /**
   * Выполняет переход на страницу результатов поиска по СНИЛС/УКП.
   *
   * @param {object} params Поисковые параметры.
   */
  const navToSnilsSearchResults = (params) => {
    const searchParams = createSearchParams(params).toString();

    navigate({
      pathname: "/applicant",
      search: searchParams,
    });
  };

  /**
   * Выполняет переход на страницу поиска приказов на зачисление.
   *
   * @param {object} params Поисковые параметры.
   */
  const navToOrdersList = (params = {}) => {
    const searchParams = createSearchParams(params).toString();

    navigate({
      pathname: "/orders",
      search: searchParams,
    });
  };

  return {
    navToSearch,
    navToApplicantsLists,
    navToSnilsSearchResults,
    navToOrdersList,
  };
};
