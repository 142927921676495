import React, { useEffect, useState } from "react";
import styles from "./SnilsSearchResults.module.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import useTitle from "../../utils/useTitle";
import useApi from "../../utils/useApi";
import Spinner from "../../components/ui/Spinner/Spinner";
import { useSearchParams } from "react-router-dom";
import StudyProgramCardButton from "../../components/StudyProgramCardButton/StudyProgramCardButton";
import formatCode from "../../utils/formatCode";
import { useAppNav } from "../../utils/useAppNav";
import SVGIcon from "../../components/SVGIcon";
import Link from "../../components/ui/Link";
import { useAppContext } from "../../App";
import Button from "../../components/ui/Button/Button";

/**
 * Компонент страницы результатов поиска по снилс/укп.
 *
 * @return {JSX.Element} React-элемент страницы.
 */
const SnilsSearchResults = () => {
  const { getRequest, cancelSource } = useApi();

  const [searchParams] = useSearchParams();
  const { navToApplicantsLists } = useAppNav();

  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({});
  const [isError, setIsError] = useState(false);
  const [mainHeight, setMainHeight] = useState(getMaxHeight());

  const { errorMessage, setErrorMessage, setPagesState } = useAppContext();

  useTitle("Поиск по СНИЛС/УКП");

  // Слушаем изменение URL-параметров

  useEffect(() => {
    setIsLoading(true);

    const snilsNum = searchParams.get("code") ?? "";

    if (snilsNum?.length === 9 || snilsNum?.length === 11) {
      getRequest("/search/applications/", { code: snilsNum })
        .then((res) => {
          setState({
            snils: snilsNum,
            data: res,
          });
        })
        .catch((error) => {
          console.log(error);
          setIsError(true);
          setErrorMessage("Произошла непредвиденная ошбка");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Неверный СНИЛС/УКП");
    }
    return () => cancelSource();
  }, [searchParams]);

  function findResultsQtyText(count = 0) {
    if (count === 0) {
      return "Заявлений не найдено";
    }
    if (count === 1) {
      return `Найдено ${count} заявление`;
    }

    let lastSymbol = Number(count.toString()[count.toString().length - 1]);
    if (lastSymbol === 2 || lastSymbol === 3 || lastSymbol === 4) {
      return `Найдено ${count} заявления`;
    }
    if (lastSymbol === 0 || lastSymbol >= 5) {
      return `Найдено ${count} заявлений`;
    }
  }

  function getMaxHeight() {
    let newheightFooterAndHeader = 500;
    const header = document.querySelector("header");
    const footer = document.querySelector("footer");
    const rectHeader = header?.getBoundingClientRect();
    const rectFooter = footer?.getBoundingClientRect();
    newheightFooterAndHeader =
      rectHeader?.height + rectFooter?.height || newheightFooterAndHeader;
    return `100vh - ${newheightFooterAndHeader}px`;
  }

  useEffect(() => {
    setMainHeight(getMaxHeight());

    window.addEventListener("resize", () => {
      setMainHeight(getMaxHeight());
    });
    return () => {
      window.removeEventListener("resize", () => {
        setMainHeight(getMaxHeight());
      });
    };
  }, []);

  useEffect(() => {
    const snilsNum = searchParams.get("code") ?? "";
    setPagesState((prev) => ({
      ...prev,
      code: snilsNum,
    }));
  }, [searchParams]);

  function onClickCardButton(data) {
    const newParams = {
      campaign: data?.filter?.campaign,
      studyForm: data?.filter?.studyForm,
      basis: data?.filter?.basis,
      specialty: data?.filter?.specialty,
      ...(data?.filter?.profile ? { profile: data?.filter?.profile } : ""),
      ...(data?.filter?.variant ? { variant: data?.filter?.variant } : ""),
    };
    setPagesState((prev) => ({
      ...prev,
      category: data?.category,
      requestParams: newParams,
    }));

    navToApplicantsLists(newParams);
  }

  const handleClickHomeButton = () => window.location.replace("/");

  return (
    <>
      <Header isBackButton isSnilsSearchResults />
      <main
        style={{ height: `calc(${mainHeight})` }}
        className={`${styles.main} ${
          isLoading || isError ? styles.center : ""
        }`}
      >
        {isLoading ? (
          <Spinner />
        ) : errorMessage?.length ? (
          <></>
        ) : isError ? (
          <div className={styles.homeButton}>
            <Button
              spaceV={10}
              spaceH={16}
              fontSize={15}
              radius={6}
              gap={8}
              onClick={handleClickHomeButton}
            >
              <SVGIcon name={"chevron-left"} size={18} color={"currentColor"} />{" "}
              Вернуться на страницу ввода СНИЛС/УКП
            </Button>
          </div>
        ) : (
          <div className={styles.container}>
            <div className={`${styles.wrapper} customScroll`}>
              <div className={styles.title}>
                <h1>{formatCode(state.snils)}</h1>
                <p>{findResultsQtyText(state?.data?.length)}</p>
              </div>
              <div className={`${styles.blockContainer} customScroll`}>
                {state?.data?.length !== 0 ? (
                  state?.data?.map((item, index) => (
                    <div key={index} className={`${styles.block} `}>
                      <div className={styles.blockTitle}>
                        <h2>
                          <SVGIcon name="graduation-hat-alt" />
                          {item?.campaign}
                        </h2>
                        <h2>
                          <SVGIcon name="file-check" />
                          {item?.basis}
                        </h2>
                      </div>

                      <div className={`${styles.cardsContainer} customScroll`}>
                        {item?.competitionGroups?.map((group, index) => (
                          <StudyProgramCardButton
                            key={index}
                            data={group}
                            onClick={onClickCardButton}
                          />
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.notFound}>
                    <p>Тут пока пусто, но это всегда можно исправить</p>
                    <Link url={"https://klgtu.ru/abitur/"} newTab>
                      Подать заявление{" "}
                      <SVGIcon name="arrow-up-right" size={16} />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </main>
      <Footer logo isSnilsSearchResultPage />
    </>
  );
};
export default SnilsSearchResults;
