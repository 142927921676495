import InfoCard from "../ui/InfoCard/InfoCard";
import styles from "./StudyProgramCardButton.module.css";
import { useEffect, useState } from "react";

/**
 * Компонент отражающий карточку программы.
 *
 * @param {Object} props Свойства компонента.
 * @param {object} props.data Данные для карточки.
 * @param {Function} props.onClick Функция для перехода к спискам выбранной программы.
 * @return {JSX.Element} React-элемент карточки.
 */

export default function StudyProgramCardButton({ data, onClick }) {
  function handleClick() {
    onClick(data);
  }
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  function showTooltip() {
    setIsTooltipShown(true);
  }

  function closeTooltip() {
    setIsTooltipShown(false);
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsTooltipShown(false);
    }, 3000);

    return () => clearTimeout(timerId);
  }, [isTooltipShown]);

  return (
    <button className={`${styles.container}`} onClick={handleClick}>
      <div className={styles.infoContainer}>
        <div
          onMouseOver={showTooltip}
          onMouseLeave={closeTooltip}
          onTouchStart={showTooltip}
          className={`${styles.priorityCard} ${
            data?.isTopPriority ? styles.isTopPriority : ""
          }`}
        >
          {data?.priority}
          {isTooltipShown && (
            <div className={styles.tooltip}>
              {data?.isTopPriority
                ? "Номер приоритета (является высшим)"
                : "Номер приоритета"}
            </div>
          )}
        </div>

        <div className={styles.specialityContainer}>
          {data?.profile && <h2>{data?.specialty}</h2>}
          <h1>{data?.profile ? data.profile : data?.specialty}</h1>
        </div>
      </div>
      <div className={styles.infoCards}>
        {data.badges.map((item, index) => (
          <InfoCard key={index} title={item} isSmall />
        ))}
      </div>
    </button>
  );
}
