import SVGIcon from "../../SVGIcon";
import styles from "./ResetButton.module.css";

export default function ResetButton({ onClick }) {
  return (
    <button className={styles.resetButton} onClick={onClick}>
      <SVGIcon name="refresh-ccw" />
      <span>Сбросить параметры</span>
    </button>
  );
}
