import InfoCard from "../ui/InfoCard/InfoCard";
import Title from "../ui/Title/Title";
import styles from "./ProgramInfoBlock.module.css";
import SwitchButton from "../ui/SwitchButton/SwitchButton";
import useApi from "../../utils/useApi";
import Spinner from "../ui/Spinner/Spinner";
import { useEffect } from "react";
import { useAppContext } from "../../App";

/**
 * Компонент отоюражающий блок с информацией о программе.
 *
 * @param {Object} props Свойства компонента.
 * @param {object} props.state Состояние страницы списков поступающих.
 * @param {Object} props.urlParams Обект с параметрами поиска для запроса данных.
 * @param {Function} props.setState Функция для обновления состояния.
 * @param {Function} props.setUrlParams Функция для обновления состояния параметров поиска для запроса данных.
 * @param {Function} props.setSearchParams Функция для обновления состояния параметров url-строки.
 * @param {boolean} props.isLoading Флаг состояния загрузки блока с информацией о кол-ве мест.
 * @param {Function} props.setIsLoading Функция для обновления состояния загрузки блока с информацией о кол-ве мест.
 * @return {JSX.Element} React-элемент блока.
 */

export default function ProgramInfoBlock({
  state,
  urlParams,
  setState,
  setUrlParams,
  setSearchParams,
  isLoading,
  setIsLoading,
}) {
  const { getRequest, cancelSource } = useApi();

  const { setPagesState } = useAppContext();

  useEffect(() => {
    const basis = urlParams?.basis ?? "";

    if (
      basis &&
      !state?.info?.bases?.filter?.((item) => item.id === Number(basis))[0]
        .isSelected
    ) {
      changeBasisParam(urlParams);
    }
  }, [urlParams]);

  function isPlacesInfoLegend() {
    if (state?.info?.places?.legend?.length > 0) return true;
    return false;
  }

  const PlacesInfoBlock = (
    <>
      <h3
        className={
          isPlacesInfoLegend()
            ? styles.placeInfoTitle
            : styles.placeInfoTitleLarge
        }
      >
        <div>{state?.info?.places?.count}</div>
        {` ${state?.info?.places?.description}`}
      </h3>
      {isPlacesInfoLegend() && (
        <ul className={styles.groupList}>
          {state?.info?.places?.legend.map(({ count, description }, index) => (
            <li key={index} className={styles.placeInfoDescription}>
              <div>{count}</div>
              {` ${description}`}
            </li>
          ))}
        </ul>
      )}
    </>
  );

  function changeBasisParam(newSearchParams, isUpateSearchParams = false) {
    setIsLoading((prev) => ({
      ...prev,
      placesInfoBlock: true,
      table: true,
      updateDate: true,
    }));

    setPagesState((prev) => ({
      ...prev,
      requestParams: newSearchParams,
    }));

    getRequest("/applicants-lists/", newSearchParams)
      .then((res) => {
        if (res?.result) {
          const data = res?.result;
          setState(data);

          if (isUpateSearchParams) {
            setUrlParams(newSearchParams);
            setSearchParams(newSearchParams);
          }
        } else {
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading((prev) => ({
          ...prev,
          placesInfoBlock: false,
          table: false,
          updateDate: false,
        }));
      });

    return () => cancelSource();
  }

  function onSwitchButton(isSelectRightButton) {
    let basisParam = 1;

    if (!isSelectRightButton) {
      basisParam = state?.info?.bases[1].id;
    }
    if (isSelectRightButton) {
      basisParam = state?.info?.bases[0].id;
    }

    let newSearchParams = {};

    for (let i in urlParams) {
      if (i !== "variant") {
        newSearchParams[i] = urlParams[i];
      }
    }
    newSearchParams = { ...newSearchParams, basis: basisParam.toString() };

    changeBasisParam(newSearchParams, true);
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <div className={styles.group}>
          <div className={styles.infoCardGroup}>
            {state?.info?.badges?.map((item, index) => (
              <InfoCard key={index} title={item} />
            ))}
          </div>
          <div className={styles.titleGroup}>
            {state?.info?.profile && (
              <h2 className={styles.titleSmall}>{state?.info?.specialty}</h2>
            )}
            <Title
              level={1}
              children={
                state?.info?.profile
                  ? state?.info?.profile
                  : state?.info?.specialty
              }
            />
          </div>
          <div>
            {state?.info?.bases?.length > 0 && urlParams?.basis && (
              <SwitchButton
                onSwitchButton={onSwitchButton}
                values={state?.info?.bases}
              />
            )}
          </div>
        </div>
        <div className={`${styles.group} ${isLoading ? styles.center : ""}`}>
          {isLoading ? <Spinner /> : state?.info?.places && PlacesInfoBlock}
        </div>
      </div>
    </div>
  );
}
