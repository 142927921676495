import { useState, useEffect, useRef, useCallback } from "react";
import SearchInput from "../ui/SearchInput/SearchInput";
import styles from "./SearchGroup.module.css";
import List from "../ui/List/List";
import { useAppContext } from "../../App";
import {
  AdmissionsCompany,
  BasisForAdmission,
  DefaultValue,
  StudyForms,
} from "../../utils/DataList";
import useApi from "../../utils/useApi";
import { useAppNav } from "../../utils/useAppNav";
import useDebounce from "../../utils/useDebounce";
import { AnimatePresence, motion } from "framer-motion";

/**
 * Компонент группы поиска.
 *
 * @param {Object} props Свойства компонента.
 * @param {object} props.state Состояние страницы поиска.
 * @return {JSX.Element} React-элемент группы поиска.
 */

export default function SearchGroup({ state }) {
  const [searchState, setSearchState] = useState({
    isLoading: false,
    inputValue: "",
    isListVisible: false,
  });
  const [searchResponseData, setSearchResponseData] = useState({
    searchData: null,
    fullData: null,
  });
  const [top, setTop] = useState(0);
  const [screenWidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const [isKeyActivated, setIsKeyActivated] = useState(false);
  const [minHeight, setMinHeight] = useState(0);

  const debouncedInputValue = useDebounce(searchState.inputValue, 500);
  const { setErrorMessage, setPagesState } = useAppContext();
  const { getRequest, cancelSource } = useApi();
  const { navToApplicantsLists } = useAppNav();

  const searchRef = useRef(null);

  // фокусоровка поиска, снятие фокусировки с селекторов, скролл при активации поиска и отображении списка

  useEffect(() => {
    setMinHeight(getMinHeight());
    if (searchState.isListVisible && searchRef?.current) {
      const rect = searchRef?.current?.getBoundingClientRect();
      if (screenWidth > 1024) {
        document.querySelector("#searchInput").focus({
          preventScroll: true,
          focusVisible: false,
        });
      } else {
        const focusedElements = document?.querySelectorAll(":focus");
        if (focusedElements?.length > 0) {
          focusedElements.forEach((el) => el.blur());
        }
      }
      setTop(rect.top);
    }
  }, [searchState.isListVisible, searchRef, screenWidth]);

  const scrollIntoView = useCallback(() => {
    if (searchState.isListVisible && searchRef?.current?.children?.[1] && top) {
      searchRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [top, searchState.isListVisible, screenWidth, minHeight]);

  useEffect(() => {
    scrollIntoView();
  }, [top, scrollIntoView]);

  // закрытие списка при изменении ширины экрана
  useEffect(() => {
    onCloseList();
  }, [screenWidth]);

  // обработчик изменения ширины экрана
  useEffect(() => {
    const getScreenWidth = () =>
      setScreenWidth(document.documentElement.clientWidth);

    window.addEventListener("resize", () => {
      getScreenWidth();
    });
    return () => {
      window.removeEventListener("resize", () => {
        getScreenWidth();
      });
    };
  }, []);

  // Запрашиваем образовательные программы после заполнения последнего селекта

  useEffect(
    () => {
      if (state[BasisForAdmission.description]?.value !== DefaultValue) {
        setSearchState((prev) => ({
          ...prev,
          inputValue: "",
          isLoading: true,
          isListVisible: true,
        }));

        getRequest(`/search/programs/`, {
          campaign: state[AdmissionsCompany.description].value.uid,
          studyForm: state[StudyForms.description].value.uid,
          basis: state[BasisForAdmission.description].value.uid,
        })
          .then((res) => {
            if (res && res !== "canceled") {
              const data = res.result.map((item, index) => ({
                name: item.title,
                id: index,
                resData: { ...item },
              }));
              setSearchResponseData({
                searchData: data,
                fullData: data,
              });
            } else if (res !== "canceled") {
              setErrorMessage(res);
              console.log(res);
            }
          })
          .catch((error) => {
            setErrorMessage("Произошла непредвиденная ошбка");
            console.log(error);
          })
          .finally(() => {
            setSearchState((prev) => ({
              ...prev,
              isLoading: false,
            }));
          });
      } else {
        setSearchResponseData({
          searchData: null,
          fullData: null,
        });
      }
      return () => cancelSource();
    },
    [state[BasisForAdmission.description].value],
    state[AdmissionsCompany.description].value,
    state[StudyForms.description].value
  );

  // Отслеживаем изменение значения поля ввода
  useEffect(() => {
    const hasValue = searchState.inputValue.trim().length > 1;

    if (hasValue) {
      setSearchState((prev) => ({
        ...prev,
        isLoading: true,
      }));
      setSearchResponseData((prev) => ({
        ...prev,
        searchData: null,
      }));
      getRequest(`/search/programs/`, {
        campaign: state[AdmissionsCompany.description].value.uid,
        studyForm: state[StudyForms.description].value.uid,
        basis: state[BasisForAdmission.description].value.uid,
        search: searchState.inputValue,
      })
        .then((res) => {
          if (res?.result) {
            setSearchResponseData((prev) => ({
              ...prev,
              searchData: res.result.map((item, index) => ({
                name: item.title,
                id: index,
                resData: { ...item },
              })),
            }));
          } else {
          }
        })
        .catch((error) => {
          setErrorMessage(error);
        })
        .finally(() => {
          setSearchState((prev) => ({
            ...prev,
            isLoading: false,
          }));
        });
    }

    if (!hasValue) {
      setSearchResponseData((prev) => ({
        ...prev,
        searchData: prev.fullData,
      }));
      if (searchResponseData.searchData) {
        setSearchState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    }

    return () => cancelSource();
  }, [debouncedInputValue]);

  useEffect(() => {
    const hasValue = searchState.inputValue.trim().length > 1;
    if (hasValue) {
      setSearchState((prev) => ({
        ...prev,
        isLoading: true,
      }));
    }
    if (!hasValue) {
      setSearchResponseData((prev) => ({
        ...prev,
        searchData: prev.fullData,
      }));
      if (searchResponseData.searchData) {
        setSearchState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    }
  }, [searchState.inputValue]);

  function getMinHeight() {
    if (searchRef?.current) {
      let newMinHeight = 0;
      const footer = document.querySelector("footer");
      const rectFooter = footer.getBoundingClientRect();

      if (searchState.isListVisible) {
        const minHeight = Math.floor(
          document.documentElement.clientHeight - rectFooter.height - 48
        );
        newMinHeight = minHeight;
      }

      return newMinHeight;
    }
    return 0;
  }

  function openList() {
    setSearchState((prev) => ({
      ...prev,
      isListVisible: true,
    }));
  }

  function handleChange(e) {
    setSearchState((prev) => ({
      ...prev,
      inputValue: e.target.value,
    }));
  }

  function clearInputValue() {
    setSearchState((prev) => ({
      ...prev,
      inputValue: "",
    }));
    setSearchResponseData((prev) => ({
      ...prev,
      searchData: prev.fullData,
    }));
  }

  const onCloseList = () => {
    setSearchState((prev) => ({
      ...prev,
      isListVisible: false,
    }));
    setIsKeyActivated(false);
  };

  function handleClickItem(index) {
    let data = {};
    if (searchState.inputValue.length > 0) {
      data = searchResponseData.searchData[index];
    }
    if (!searchState.inputValue.length) {
      data = searchResponseData.fullData[index];
    }

    const newParams = {
      campaign: state[AdmissionsCompany.description].value.uid,
      studyForm: state[StudyForms.description].value.uid,
      basis: state[BasisForAdmission.description].value.uid,
      specialty: data.resData.specialty,
      ...(data.resData.profile ? { profile: data.resData.profile } : ""),
    };

    setPagesState((prev) => ({
      ...prev,
      requestParams: newParams,
    }));

    navToApplicantsLists(newParams);
  }

  return (
    <motion.div
      layout="position"
      ref={searchRef}
      style={
        minHeight !== 0 && screenWidth <= 1024
          ? {
              minHeight: `${minHeight}px`,
              ...(minHeight > 340
                ? { height: `${minHeight}px` }
                : { height: "340px" }),
            }
          : {}
      }
      className={styles.searchContainer}
    >
      <SearchInput
        listName="list"
        description={"ОБРАЗОВАТЕЛЬНАЯ ПРОГРАММА"}
        placeholder={"Поиск по ключевым словам или номеру"}
        inputValue={searchState.inputValue}
        handleChange={handleChange}
        clearInputValue={clearInputValue}
        openList={openList}
        onCloseList={onCloseList}
        isKeyActivated={isKeyActivated}
        setIsKeyActivated={setIsKeyActivated}
        isDisabled={
          (state[AdmissionsCompany.description].value === DefaultValue) |
          (state[StudyForms.description].value === DefaultValue) |
          (state[BasisForAdmission.description].value === DefaultValue)
        }
        autoFocus={false}
        isfocusStyle={searchState.isListVisible && screenWidth <= 1024}
      />
      <AnimatePresence>
        {searchState.isListVisible && (
          <div className={styles.listContainer}>
            <List
              list={searchResponseData.searchData}
              onClickItem={handleClickItem}
              onCloseList={onCloseList}
              isNotFoundMessage={
                !searchState.isLoading &&
                !(searchResponseData.searchData?.length > 0) &&
                searchState.inputValue
              }
              isKeyActivated={isKeyActivated}
              isLoading={searchState.isLoading}
            />
          </div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
