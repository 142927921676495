import { useEffect, useRef, useState } from "react";
import styles from "./Select.module.css";
import { DefaultValue } from "../../../utils/DataList";
import List from "../List/List";
import useKeyDown from "../../../utils/useKeyDown";
import { AnimatePresence } from "framer-motion";

/**
 * Компонент селектора.
 *
 * @param {Object} props Свойства компонента.
 * @param {value[]} props.list Выпадающий список.
 * @param {Object} props.value Значение в инпуте селекта.
 * @param {string} props.description Описания селекта.
 * @param {boolean} props.isDisabled Флаг для блокировки селекта.
 * @param {Function} props.handleClick Функция для изменения знаяения в инпуте селекта.
 * @param {boolean} props.isOpen Флаг для открытия выпадающего списка.
 * @param {boolean} props.children Дочерний элемент.
 * @return {JSX.Element} React-элемент селектора.
 */

export default function Select({
  list,
  value = DefaultValue,
  description,
  isDisabled,
  handleClick,
  isOpen,
  children,
}) {
  const [isListVisible, setIsListVisible] = useState(false);
  const [isKeyActivated, setIsKeyActivated] = useState(false);

  const buttonRef = useRef(null);

  const { handleKeyDown } = useKeyDown({ isKeyActivated, setIsKeyActivated });
  useKeyDown({ isKeyActivated, setIsKeyActivated });

  useEffect(() => {
    if (isOpen === true) {
      setIsListVisible(true);
      setButtonFocus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isListVisible && buttonRef?.current) {
      if (document.querySelector(":focus") !== buttonRef?.current) {
        buttonRef?.current?.focus({ preventScroll: true, focusVisible: false });
      }
      buttonRef?.current?.parentNode.parentNode.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [buttonRef, isListVisible]);

  function onClick() {
    if (isListVisible === false) {
      setButtonFocus();
    }

    setIsListVisible((prev) => !prev);
  }

  function onClickItem(id) {
    handleClick(id, description);
  }

  function setButtonFocus() {
    buttonRef?.current?.focus({
      preventScroll: true,
      focusVisible: false,
    });
    setIsKeyActivated(false);
  }

  function onCloseList() {
    setIsListVisible(false);
    setIsKeyActivated(false);
  }

  return (
    <div className={styles.container}>
      <p className={styles.description}>{description}</p>
      <div className={styles.selectContainer}>
        <button
          ref={buttonRef}
          className={styles.selectGroup}
          disabled={isDisabled}
          onClick={onClick}
          onKeyDown={handleKeyDown}
        >
          {children}
        </button>
        <AnimatePresence>
          {isListVisible && list.length > 0 && (
            <div className={styles.listContainer} onClick={setButtonFocus}>
              <List
                onClickItem={onClickItem}
                onCloseList={onCloseList}
                list={list}
                nowrap
                isAbsolute
                isKeyActivated={isKeyActivated}
              />
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
