export default function formatCode(code) {
  let currentCode = code?.replace(/[^\d]/g, "");

  if (currentCode) {
    if (currentCode?.length > 9) {
      const startCode =
        currentCode.slice(0, 3) +
        "-" +
        currentCode.slice(3, 6) +
        "-" +
        currentCode.slice(6, 9);

      const endCode = currentCode.slice(9, 11);
      return `${startCode} ${endCode}`;
    }
    return currentCode;
  }
  return "";
}
