import { useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { useEffect, useState } from "react";
import styles from "./ApplicantsLists.module.css";
import ProgramInfoBlock from "../../components/ProgramInfoBlock/ProgramInfoBlock";
import useApi from "../../utils/useApi";
import useTitle from "../../utils/useTitle";
import Spinner from "../../components/ui/Spinner/Spinner";
import ApplicantsTableBlock from "../../components/ApplicantsTableBlock/ApplicantsTableBlock";
import { useAppContext } from "../../App";
import Button from "../../components/ui/Button/Button";
import SVGIcon from "../../components/SVGIcon";

/**
 * Компонент страницы списков поступающих.
 *
 * @return {JSX.Element} React-элемент страницы.
 */

export default function ApplicantsLists() {
  const [urlParams, setUrlParams] = useState(null);
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState({
    allPage: true,
    placesInfoBlock: false,
    table: false,
    updateDate: false,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const { getRequest, cancelSource } = useApi();
  const { errorMessage, setErrorMessage } = useAppContext();

  useTitle(state?.info?.specialty);

  // Слушаем изменение URL-параметров и делаем запрос для получения данных
  useEffect(() => {
    const campaignUid = searchParams.get("campaign") ?? "";
    const studyFormUid = searchParams.get("studyForm") ?? "";
    const specialtyUid = searchParams.get("specialty") ?? "";
    const profileUid = searchParams.get("profile") ?? "";
    const basisUid = searchParams.get("basis") ?? "";
    const variantUid = searchParams.get("variant") ?? "";
    const newUrlParams = {
      campaign: campaignUid,
      studyForm: studyFormUid,
      specialty: specialtyUid,
      ...(profileUid ? { profile: profileUid } : ""),
      basis: basisUid,
      ...(variantUid ? { variant: variantUid } : ""),
    };
    if (!urlParams) {
      setIsLoading((prev) => ({
        ...prev,
        allPage: state ? false : true,
        table: true,
        placesInfoBlock: true,
      }));
      setUrlParams(newUrlParams);
      getRequest("/applicants-lists/", newUrlParams)
        .then((res) => {
          if (res?.result && res !== "canceled") {
            const resData = res.result;
            setState(resData);
          } else if (res !== "cenceled") {
            console.log(res);
            setErrorMessage(res);
          }
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage("Произошла непредвиденная ошбка");
        })
        .finally(() => {
          setIsLoading((prev) => ({
            ...prev,
            allPage: false,
            table: false,
            placesInfoBlock: false,
          }));
        });
      return () => cancelSource();
    } else {
      setUrlParams(newUrlParams);
    }
  }, [searchParams]);

  const handleClickHomeButton = () => window.location.replace("/");

  return (
    <>
      <Header
        isBackButton
        requestParams={urlParams}
        isUpdateDate={state?.categories ? true : false}
        isLoadingUpdateDate={isLoading.updateDate}
        date={state?.info?.updateDate}
      />
      <main
        className={`${styles.main} ${
          isLoading.allPage ||
          errorMessage?.length ||
          !(state?.categories?.length > 0)
            ? styles.center
            : ""
        }`}
      >
        {isLoading.allPage ? (
          <Spinner />
        ) : errorMessage?.length ? (
          <></>
        ) : !(state?.categories?.length > 0) ? (
          <Button
            spaceV={10}
            spaceH={16}
            fontSize={15}
            radius={6}
            gap={8}
            onClick={handleClickHomeButton}
          >
            <SVGIcon name={"chevron-left"} size={18} color={"currentColor"} />{" "}
            Вернуться на страницу выбора
          </Button>
        ) : (
          <>
            <ProgramInfoBlock
              state={state}
              urlParams={urlParams}
              setUrlParams={setUrlParams}
              setSearchParams={setSearchParams}
              setState={setState}
              isLoading={isLoading.placesInfoBlock}
              setIsLoading={setIsLoading}
            />

            <div className={styles.tableCotainer}>
              <ApplicantsTableBlock
                state={state}
                setState={setState}
                urlParams={urlParams}
                setUrlParams={setUrlParams}
                setSearchParams={setSearchParams}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </div>
          </>
        )}
      </main>
      <Footer logo />
    </>
  );
}
