import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import Logo from "../ui/Logo/Logo";
import SVGIcon from "../SVGIcon";
import { useAppNav } from "../../utils/useAppNav";
import UpdateDateCard from "../UpdateDateCard/UpdateDateCard";
import ModalId from "../../utils/ModalId";
import { useAppContext } from "../../App";

/**
 * Компонент для отображения шапки.
 *
 * @param {Object} props Свойства компонента.
 * @param {boolean} props.isBackButton Флаг, указывающий на отображение кнопки "Назад".
 * @param {boolean} props.isUpdateDate Флаг, указывающий на отображение блока со временем последнего обновления данных.
 * @param {boolean} props.isLoadingUpdateDate Флаг загрузки информации для блока со временем последнего обновления данных.
 * @param {string} props.date Дата обнолвения информации списков
 * @param {boolean} props.isSnilsSearchResults Флаг отображения блока с заголовком страницы результатов поиска по СНИЛС/УКП
 * @param {boolean} props.isOrdersSearch Флаг отображения блока с заголовком страницы поиска приказов
 * @param {object} props.requestParams Поисковые параметры.
 * @return {JSX.Element} React-элемент шапки.
 */
const Header = ({
  isBackButton,
  isUpdateDate = false,
  isLoadingUpdateDate = false,
  date,
  isSnilsSearchResults,
  isOrdersSearch,
  requestParams = {},
}) => {
  const { navToSearch } = useAppNav();
  const { showModal, setPagesState } = useAppContext();

  const [screenWidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );

  useEffect(() => {
    const setWindowDimensions = () =>
      setScreenWidth(document.documentElement.clientWidth);

    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  /**
   * Компонент для отображения кнопки возвращения к выбору расписания.
   *
   * @return {JSX.Element} React-элемент кнопки.
   * @param {object} props.params Поисковые параметры.
   */
  const BackButton = ({ params }) => {
    /**
     * Обрабатывает нажатие кнопки.
     */
    const handleClick = () => {
      setPagesState((prev) => ({
        ...prev,
        requestParams: params,
      }));

      navToSearch();
    };

    return (
      <button className={styles.linkButton} onClick={handleClick}>
        <SVGIcon name={"chevron-left"} size={16} color={"currentColor"} />
        {screenWidth < 1024 ? "Выбор" : "Выбор рейтинга"}
      </button>
    );
  };

  const handleClick = () => showModal(ModalId.HOW_ADMISSION_WORK);

  return (
    <header className={styles.header}>
      <div className={styles.innerContainer}>
        {isBackButton ? <BackButton params={requestParams} /> : <Logo />}
        <div className={styles.middleBlock}>
          {isUpdateDate && !isLoadingUpdateDate && date && (
            <UpdateDateCard date={date} />
          )}
          {isSnilsSearchResults && (
            <h2 className={styles.headerTitle}>Поиск по СНИЛС/УКП</h2>
          )}
          {isOrdersSearch && (
            <h2 className={styles.headerTitle}>Приказы о зачислении</h2>
          )}
        </div>
        <button
          className={styles.linkButton}
          onClick={handleClick}
          style={
            (isSnilsSearchResults || isOrdersSearch) && screenWidth < 480
              ? screenWidth < 320
                ? { textWrap: "wrap" }
                : screenWidth < 360
                ? { textWrap: "nowrap" }
                : { textWrap: "wrap" }
              : isUpdateDate && screenWidth < 480 && date
              ? { textWrap: "wrap" }
              : {}
          }
        >
          Как проходит зачисление?
        </button>
      </div>
    </header>
  );
};

export default Header;
