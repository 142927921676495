import {
  AdmissionsCompany,
  BasisForAdmission,
  StudyForms,
} from "../../utils/DataList";
import Select from "../../components/ui/Select/Select";
import styles from "./SelectGroup.module.css";
import InputValueItem from "../ui/InputValueItem/InputValueItem";

/**
 * Компонент группы селекторов.
 *
 * @param {Object} props Свойства компонента.
 * @param {object} props.data Данные для селекторов (получаем из запроса).
 * @param {object} props.state Состояние страницы поиска.
 * @param {Function} props.setState Функция для изменения состояния страницы поиска.
 * @param {Function} props.onChangeValue Функция для изменения значений селекторов.
 * @return {JSX.Element} React-элемент группы селекторов.
 */

export default function SelectGroup({ data, state, setState, onChangeValue }) {
  function onChange(id, description) {
    const newState = onChangeValue(id, description, data, state);
    setState(newState);
  }

  return (
    <div className={styles.selectGroup}>
      <Select
        list={state[AdmissionsCompany.description].list}
        value={state[AdmissionsCompany.description].value}
        description={AdmissionsCompany.description}
        isDisabled={state[AdmissionsCompany.description].isDisabled}
        handleClick={onChange}
        isOpen={state[AdmissionsCompany.description].isOpen}
      >
        <InputValueItem
          value={state[AdmissionsCompany.description].value.name}
          isDisabled={state[AdmissionsCompany.description].isDisabled}
        />
      </Select>
      <Select
        list={state[StudyForms.description].list}
        value={state[StudyForms.description].value}
        description={StudyForms.description}
        isDisabled={state[StudyForms.description].isDisabled}
        handleClick={onChange}
        isOpen={state[StudyForms.description].isOpen}
      >
        <InputValueItem
          value={state[StudyForms.description].value.name}
          isDisabled={state[StudyForms.description].isDisabled}
        />
      </Select>
      <Select
        list={state[BasisForAdmission.description].list}
        value={state[BasisForAdmission.description].value}
        description={BasisForAdmission.description}
        isDisabled={state[BasisForAdmission.description].isDisabled}
        handleClick={onChange}
        isOpen={state[BasisForAdmission.description].isOpen}
      >
        <InputValueItem
          value={state[BasisForAdmission.description].value.name}
          isDisabled={state[BasisForAdmission.description].isDisabled}
        />
      </Select>
    </div>
  );
}
