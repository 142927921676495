import { useState, useEffect, useRef, useCallback } from "react";
import SearchInput from "../ui/SearchInput/SearchInput";
import styles from "./OrdersSearchGroup.module.css";
import { useAppContext } from "../../App";
import useApi from "../../utils/useApi";
import Title from "../ui/Title/Title";
import CountItem from "../ui/CountItem/CountItem";
import Spinner from "../ui/Spinner/Spinner";
import SVGIcon from "../SVGIcon";
import OrderCard from "../ui/OrderCard/OrderCard";
import RadioButtonsGroup from "../RadioButtonsGroup/RadioButtonsGroup";
import ResetButton from "../ui/ResetButton/ResetButton";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import Button from "../ui/Button/Button";
import useDebounce from "../../utils/useDebounce";
import InfoCard from "../ui/InfoCard/InfoCard";
import { useSearchParams } from "react-router-dom";

/**
 * Компонент группы поиска приказов.
 */

const sortByDate = [{ name: "Сначала новые" }, { name: "Сначала старые" }];

export default function OrdersSearchGroup() {
  const [searchState, setSearchState] = useState({
    isLoading: false,
    isLoadingStudyFormsFilters: false,
    isLoadingBasesFilter: false,
    inputValue: "",
    isFiltersVisible: false,
    sortByDateFilter: {
      key: "sortByDate",
      name: "Сортировка по дате",
      values: [
        { name: sortByDate[0].name, isSelected: true, isActive: true },
        { name: sortByDate[1].name, isSelected: false, isActive: true },
      ],
    },
    campaign: null,
    studyForm: null,
    basis: null,
    selectedFilters: [
      // { key: "sortByDate", name: sortByDate[0].name, isAllowDelete: false },
    ],
    // category: null,
  });
  const [searchResponseData, setSearchResponseData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [params, setParams] = useState(null);
  const [minHeight, setMinHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const [isChangeSearchParams, setIsChangeSearchParams] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const debouncedInputValue = useDebounce(searchState?.inputValue, 500);

  const { setErrorMessage, pagesState, setPagesState, errorMessage } =
    useAppContext();

  const { getRequest, cancelSource } = useApi();

  const [searchParams, setSearchParams] = useSearchParams();

  const searchRef = useRef(null);
  const filterListRef = useRef(null);
  const filterButtonRef = useRef(null);
  const orderCardsRef = useRef(null);

  useEffect(() => {
    if (isChangeSearchParams) {
      setIsChangeSearchParams(false);
    }
    if (!isChangeSearchParams) {
      setSearchState((prev) => ({
        ...prev,
        isLoading: true,
      }));
      const orderById = searchParams.get("order_by") ?? "";
      const campaignUid = searchParams.get("campaign") ?? "";
      const studyFormUid = searchParams.get("studyForm") ?? "";
      const basisUid = searchParams.get("basis") ?? "";
      const searchText = searchParams.get("search") ?? "";
      const newFIltersArray = searchState?.sortByDateFilter?.values?.map(
        (item) => {
          if (item.name === sortByDate[0].name) {
            return { ...item, isSelected: orderById === "" ? true : false };
          } else {
            return { ...item, isSelected: orderById === "asc" ? true : false };
          }
        }
      );

      if (pagesState?.code) {
        setPagesState({
          code: "",
          category: "",
        });
      }

      setSearchState((prev) => ({
        ...prev,
        ...(searchText ? { inputValue: searchText } : ""),
        sortByDateFilter: {
          ...prev.sortByDateFilter,
          values: newFIltersArray,
        },
      }));

      let newParams = {
        ...(orderById ? { order_by: orderById } : ""),
        ...(campaignUid ? { campaign: campaignUid } : ""),
        ...(studyFormUid ? { studyForm: studyFormUid } : ""),
        ...(basisUid ? { basis: basisUid } : ""),
        ...(searchText ? { search: searchText } : ""),
      };

      setParams(newParams);
    }
  }, [searchParams]);

  useEffect(() => {
    getScreenWidth();

    window.addEventListener("resize", () => {
      getScreenWidth();
    });
    return () => {
      window.removeEventListener("resize", () => {
        getScreenWidth();
      });
    };
  }, []);

  //   Закрытие списка с фильтрами и сортировкой при нажатии вне его области

  const onClose = useCallback(
    (e) => {
      const isContain =
        screenWidth <= 1024
          ? orderCardsRef?.current?.contains(e.target)
            ? true
            : false
          : false;

      if (
        filterListRef?.current?.parentNode &&
        !filterListRef?.current?.parentNode?.contains(e.target) &&
        !filterButtonRef?.current?.contains(e.target) &&
        !isContain
      ) {
        setSearchState((prev) => ({
          ...prev,
          isFiltersVisible: false,
        }));
      }
    },
    [screenWidth]
  );

  useEffect(() => {
    document.addEventListener("mousedown", onClose);
    return () => document.removeEventListener("mousedown", onClose);
  }, [onClose]);

  useEffect(() => {
    const hasValue = searchState?.inputValue?.trim()?.length > 1;
    const searchText = searchParams.get("search") ?? "";

    if (hasValue && debouncedInputValue !== searchText) {
      setParams((prev) => ({
        ...prev,
        search: debouncedInputValue,
      }));
    }
    if (!isFirstRender && !hasValue && debouncedInputValue !== searchText) {
      let newParams = {};
      for (let param in params) {
        if (param !== "search") {
          newParams[param] = params[param];
        }
      }
      setParams(newParams);
    }
  }, [debouncedInputValue]);

  // calculate min height
  useEffect(() => {
    if (
      searchState.isFiltersVisible &&
      screenWidth > 1024 &&
      filterListRef?.current
    ) {
      const searchHeight =
        document.querySelector("#orders-search").clientHeight;
      const filtersHeight = filterListRef?.current?.parentNode?.clientHeight;
      const minHeight = searchHeight + filtersHeight;
      setMinHeight(minHeight);
    } else {
      setMinHeight(0);
    }
  }, [searchState.isFiltersVisible, screenWidth]);

  // Запрашиваем приказы после изменения фильтров или значения поиска

  useEffect(() => {
    if (params) {
      // const orderById = searchParams.get("order_by") ?? "";
      // const campaignUid = searchParams.get("campaign") ?? "";
      // const studyFormUid = searchParams.get("studyForm") ?? "";
      // const basisUid = searchParams.get("basis") ?? "";
      const searchText = searchParams.get("search") ?? "";

      setSearchState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      let initialParams = {};
      // const dateSelectedFilter =
      //   searchState?.sortByDateFilter?.values?.filter(
      //     (item) => item.isSelected
      //   );
      // const dateFilterSelectedValue = dateSelectedFilter?.[0]?.name;

      setSelectedFilersCards(searchState);
      const requestParams = {
        ...(!!params && params),
        order_by: searchState.sortByDateFilter?.values?.[0]?.isSelected
          ? "desc"
          : "asc",
      };
      getRequest(`/search/orders/`, requestParams)
        .then((res) => {
          if (!!res && res !== "canceled") {
            setSearchResponseData(res);
            initialParams = {
              campaign: res?.filters?.[0] ?? null,
              studyForm: res?.filters?.[1] ?? null,
              basis: res?.filters?.[2] ?? null,
            };
            setSearchState((prev) => ({
              ...prev,
              ...initialParams,
              ...(!params?.search ? { inputValue: "" } : ""),
              // selectedFilters: newSelectedFilters,
              isLoading: false,
              isLoadingStudyFormsFilters: false,
              isLoadingBasesFilter: false,
            }));
            setSelectedFilersCards(initialParams);
            let isChangeParams = false;

            if (
              Object.keys(params).length > 0 &&
              searchParams.size === Object.keys(params).length
            ) {
              for (let variable in params) {
                const variableUid = searchParams.get(variable) ?? "";
                if (params[variable] !== variableUid) {
                  isChangeParams = true;
                }
              }
            }

            if (searchParams.size > 0 || Object.keys(params).length > 0) {
              if (
                searchParams.size !== Object.keys(params).length ||
                isChangeParams
              ) {
                setSearchParams(params);
                setIsChangeSearchParams(true);
              }
            }
            setIsFirstRender(false);
          } else if (res !== "canceled") {
            console.log("error then", res);
            setIsError(true);
            setErrorMessage(res);
            setSearchState((prev) => ({
              ...prev,
              // selectedFilters: newSelectedFilters,
              campaign: searchResponseData?.filters?.[0],
              studyForm: searchResponseData?.filters?.[1],
              basis: searchResponseData?.filters?.[2],
              isLoading: false,
              isLoadingStudyFormsFilters: false,
              isLoadingBasesFilter: false,
            }));
          }
        })
        .catch((error) => {
          setIsError(true);
          setErrorMessage("Произошла непредвиденная ошбка");
          console.log("error catch", error);
          setSearchState((prev) => ({
            ...prev,
            // selectedFilters: newSelectedFilters,
            campaign: searchResponseData?.filters?.[0],
            studyForm: searchResponseData?.filters?.[1],
            basis: searchResponseData?.filters?.[2],
            isLoading: false,
            isLoadingStudyFormsFilters: false,
            isLoadingBasesFilter: false,
          }));
        });

      // return () => cancelSource();
    }
  }, [params]);

  function setSelectedFilersCards(state) {
    let newSelectedFilters = [
      // {
      //   name: dateFilterSelectedValue,
      //   isAllowDelete: false,
      //   key: "sortByDate",
      // },
    ];
    if (params && Object.keys(params)?.length > 0) {
      for (let variable in params) {
        if (variable !== "order_by" && variable !== "search") {
          const selectedFilter = state?.[variable]?.values?.filter(
            (item) => item.isSelected
          );
          if (selectedFilter?.length > 0) {
            const selectedFilterName = selectedFilter[0]?.name;
            newSelectedFilters = [
              ...newSelectedFilters,
              {
                name: selectedFilterName,
                isAllowDelete: true,
                key: variable,
              },
            ];
          }
        }
      }
    }

    setSearchState((prev) => ({
      ...prev,
      selectedFilters: newSelectedFilters,
    }));
  }
  const getScreenWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };

  function handleChange(e) {
    setSearchState((prev) => ({
      ...prev,
      inputValue: e.target.value,
    }));
  }

  function clearInputValue() {
    setSearchState((prev) => ({
      ...prev,
      inputValue: "",
    }));
    let newParams = {};
    for (let variable in params) {
      if (variable !== "search") {
        newParams[variable] = params[variable];
      }
    }
    setParams(newParams);
  }

  function onChangeSortByDate(e) {
    const newFIltersArray = searchState?.sortByDateFilter?.values?.map(
      (item) => {
        if (item.name === e.target.value) {
          return { ...item, isSelected: true };
        } else {
          return { ...item, isSelected: false };
        }
      }
    );

    setSearchState((prev) => ({
      ...prev,
      sortByDateFilter: {
        ...prev.sortByDateFilter,
        values: newFIltersArray,
      },
    }));

    let newParams = {};
    if (params && Object.keys(params).length > 0) {
      for (let variable in params) {
        if (variable !== "order_by") {
          newParams[variable] = params[variable];
        }
      }
    }

    newParams = {
      ...newParams,
      ...(newFIltersArray?.[1]?.isSelected ? { order_by: "asc" } : ""),
    };

    setParams(newParams);
  }

  // Function for change sort by admission category

  // function onChangeSortByAdmissionCategory(e) {
  //   const newFIltersArray = searchState.category?.values?.map((item) => {
  //     if (item.name === e.target.value) {
  //       return { ...item, isSelected: item.isSelected ? false : true };
  //     }
  //     return item;
  //   });

  //   setSearchState((prev) => ({
  //     ...prev,
  //     category: newFIltersArray,
  //   }));

  //   const currentCategory = searchState.category?.values?.filter(
  //     (item) => item?.name === e.target.value
  //   );
  //   const uId = currentCategory?.[0]?.value;
  //   setParams((prev) => ({
  //     ...prev,
  //     ...(uId ? { category: uId } : ""),
  //   }));
  // }

  function onChangeEducationProgramsFilters(e) {
    const newFIltersArray = searchState?.[e.target.name]?.values?.map(
      (item) => {
        if (item.name === e.target.value) {
          return { ...item, isSelected: true };
        } else {
          return { ...item, isSelected: false };
        }
      }
    );

    setSearchState((prev) => ({
      ...prev,
      [e.target.name]: { ...prev[e.target.name], values: newFIltersArray },
    }));

    const currentCategory = searchState?.[e.target.name]?.values?.filter(
      (item) => item?.name === e.target.value
    );
    const uId = currentCategory?.[0]?.value;
    if (e.target.name === "campaign") {
      setSearchState((prev) => ({
        ...prev,
        isLoadingStudyFormsFilters: true,
        isLoadingBasesFilter: true,
      }));
      setParams((prev) => ({
        ...(prev?.order_by ? { order_by: prev.order_by } : ""),
        ...(prev?.search ? { search: prev.search } : ""),
        ...(uId ? { [e.target.name]: uId } : ""),
      }));
    } else if (e.target.name === "studyForm") {
      setSearchState((prev) => ({ ...prev, isLoadingBasesFilter: true }));
      setParams((prev) => ({
        ...(prev?.order_by ? { order_by: prev.order_by } : ""),
        ...(prev?.search ? { search: prev.search } : ""),
        ...(prev?.campaign ? { campaign: prev.campaign } : ""),
        ...(uId && prev?.campaign ? { [e.target.name]: uId } : ""),
      }));
    } else {
      setParams((prev) => ({
        ...prev,
        ...(uId && prev?.campaign && prev?.studyForm
          ? { [e.target.name]: uId }
          : ""),
      }));
    }
  }

  function toggleFilterList() {
    setSearchState((prev) => ({
      ...prev,
      isFiltersVisible: !prev.isFiltersVisible,
    }));
  }

  function resetParams() {
    setSearchState((prev) => ({
      ...prev,
      isLoadingBasesFilter: true,
      isLoadingStudyFormsFilters: true,
    }));

    const newFIltersArray = searchState?.sortByDateFilter?.values?.map(
      (item, index) => {
        if (index === 0) {
          return { ...item, isSelected: true };
        } else {
          return { ...item, isSelected: false };
        }
      }
    );

    setSearchState((prev) => ({
      ...prev,
      sortByDateFilter: {
        ...prev.sortByDateFilter,
        values: newFIltersArray,
      },
    }));

    setParams((prev) => ({
      ...(prev?.search ? { search: prev.search } : ""),
    }));
  }

  const handleClickReloadPage = () => window.location.reload();

  function deleteFilter(key) {
    return () => {
      if (key === "campaign") {
        setParams((prev) => ({
          ...(prev?.order_by ? { order_by: prev.order_by } : ""),
          ...(prev?.search ? { search: prev.search } : ""),
        }));
      } else if (key === "studyForm") {
        setParams((prev) => ({
          ...(prev?.order_by ? { order_by: prev.order_by } : ""),
          ...(prev?.search ? { search: prev.search } : ""),
          ...(prev?.campaign ? { campaign: prev.campaign } : ""),
        }));
      } else if (key === "basis") {
        setParams((prev) => ({
          ...(prev?.order_by ? { order_by: prev.order_by } : ""),
          ...(prev?.search ? { search: prev.search } : ""),
          ...(prev?.campaign ? { campaign: prev.campaign } : ""),
          ...(prev?.studyForm ? { studyForm: prev.studyForm } : ""),
        }));
      }
    };
  }

  function isVisibleResetButton() {
    let isVisible = true;
    const paramsKeys = params ? Object.keys(params) : [];
    if (params && paramsKeys.length > 0) {
      for (let variable in params) {
        if (variable === "search" && paramsKeys.length === 1) {
          isVisible = false;
        }
      }
      return isVisible;
    }
    return false;
  }

  return (
    <div
      id="orders-search-group"
      ref={searchRef}
      style={screenWidth > 1024 ? { minHeight: `${minHeight}px` } : {}}
      className={styles.mainContainer}
    >
      {isError && !(errorMessage?.length > 0) && (
        <div className={styles.center}>
          <Button
            spaceV={10}
            spaceH={16}
            fontSize={15}
            radius={6}
            gap={8}
            onClick={handleClickReloadPage}
          >
            Перезагрузить страницу
          </Button>
        </div>
      )}
      {!isError && !(errorMessage?.length > 0) && (
        <div id="orders-search" className={styles.searchContainer}>
          <div className={styles.ordersSearchTitle}>
            <Title level={3}>Приказы о зачислении</Title>
          </div>
          <div className={styles.searchGroupContainer}>
            <div id="order-search-group" className={styles.searchGroup}>
              <SearchInput
                placeholder={
                  "Образовательная программа, номера приказа или СНИЛС/УКП"
                }
                inputValue={searchState.inputValue}
                handleChange={handleChange}
                clearInputValue={clearInputValue}
                autoFocus={screenWidth > 1024 ? true : false}
              />
              <div className={styles.filtersButtonContainer}>
                <div
                  className={styles.filtersButton}
                  onClick={toggleFilterList}
                  ref={filterButtonRef}
                >
                  {searchState?.isFiltersVisible ? (
                    <SVGIcon name="x-close" />
                  ) : (
                    <SVGIcon name="filter-lines" />
                  )}
                  {screenWidth > 1024 ? "Сортировка и фильтры" : ""}
                  <CountItem isSelected>
                    {searchResponseData?.orders?.length ?? 0}
                  </CountItem>
                </div>
              </div>
            </div>
            <AnimatePresence mode="popLayout">
              {searchState.isFiltersVisible && (
                <motion.div
                  layout="position"
                  key={"filters"}
                  variants={{
                    hidden: {
                      opacity: 0,
                      x: 1000,
                    },
                    visible: {
                      opacity: 1,
                      x: 0,
                    },
                  }}
                  initial="hidden"
                  exit="hidden"
                  animate="visible"
                  transition={{
                    duration: 0.2,
                  }}
                  style={{ overflow: "hidden" }}
                  className={styles.filtersList}
                >
                  <div ref={filterListRef}>
                    <RadioButtonsGroup
                      data={searchState?.sortByDateFilter}
                      onChange={onChangeSortByDate}
                    />
                    {/* Sort by admission category */}
                    {/* {searchState?.category && (
                  <RadioButtonsGroup
                    data={searchResponseData?.searchData?.filters?.[3]}
                    onChange={onChangeSortByAdmissionCategory}
                    type={"checkbox"}
                  />
                )} */}
                    {isVisibleResetButton() && (
                      <div className={styles.resetButtonContainer}>
                        <ResetButton onClick={resetParams} />
                      </div>
                    )}
                  </div>
                  {(searchState?.campaign ||
                    searchState?.studyForm ||
                    searchState?.basis) && (
                    <div>
                      {searchState?.campaign && (
                        <RadioButtonsGroup
                          data={searchState?.campaign}
                          onChange={onChangeEducationProgramsFilters}
                        />
                      )}

                      {searchState?.studyForm && (
                        <RadioButtonsGroup
                          data={searchState.studyForm}
                          isLoading={searchState.isLoadingStudyFormsFilters}
                          onChange={onChangeEducationProgramsFilters}
                        />
                      )}
                      {searchState?.basis && (
                        <RadioButtonsGroup
                          data={searchState.basis}
                          isLoading={searchState.isLoadingBasesFilter}
                          onChange={onChangeEducationProgramsFilters}
                        />
                      )}
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}
      <LayoutGroup>
        <AnimatePresence mode={"wait"}>
          {searchState.selectedFilters?.length > 0 &&
            (screenWidth > 1024 ||
              (screenWidth <= 1024 && !searchState.isFiltersVisible)) && (
              <motion.div
                layout="position"
                className={styles.selectedFilterContainer}
                initial={{ x: 1000, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 1000, opacity: 0 }}
                transition={{
                  duration: 0.2,
                  opacity: { duration: 0.1 },
                  type: "keyframes",
                  ease: "linear",
                }}
              >
                <AnimatePresence>
                  {searchState.selectedFilters?.map((item, index) => (
                    <InfoCard
                      key={index}
                      isSmall
                      animate={{
                        // layout: "position",
                        initial: { x: screenWidth, opacity: 0 },
                        animate: { x: 0, opacity: 1 },
                        exit: { x: screenWidth, opacity: 0 },
                        transition: {
                          duration: 0.2,
                          type: "keyframes",
                          ease: "linear",
                        },
                      }}
                      title={
                        <div
                          className={`${styles.selectedFilterContent} ${
                            item.isAllowDelete ? styles.withButton : ""
                          }`}
                        >
                          <span>{item?.name}</span>
                          {item.isAllowDelete && (
                            <button onClick={deleteFilter(item.key)}>
                              <SVGIcon name="x-close" />
                            </button>
                          )}
                        </div>
                      }
                    />
                  ))}
                </AnimatePresence>
              </motion.div>
            )}
        </AnimatePresence>
        {searchState.isLoading && (
          <motion.div
            layout
            className={`${styles.center} ${
              !searchState.isFiltersVisible ? styles.animateLoader : ""
            }`}
            style={
              searchState.isFiltersVisible && screenWidth > 1024
                ? {
                    width: "50%",
                    alignSelf: "start",
                  }
                : {}
            }
          >
            <motion.div layout="position">
              <Spinner />
            </motion.div>
          </motion.div>
        )}
        {!isError &&
          !searchResponseData?.orders?.length &&
          !searchState.isLoading && (
            <motion.div
              layout={screenWidth <= 1024 ? "position" : "false"}
              initial={{
                ...(searchState.isFiltersVisible && screenWidth > 1024
                  ? { width: "52%" }
                  : { width: "100%" }),
              }}
              animate={{
                ...(searchState.isFiltersVisible && screenWidth > 1024
                  ? {
                      width: "52%",
                    }
                  : ""),
              }}
              className={styles.notFound}
            >
              <SVGIcon name="file-x" />
              <h3>Приказов пока нет</h3>
            </motion.div>
          )}
        {!isError &&
          searchResponseData?.orders?.length > 0 &&
          !searchState.isLoading && (
            <motion.div
              ref={orderCardsRef}
              layout="position"
              transition={{
                duration: 0.3,
              }}
              className={styles.listContainer}
            >
              {searchResponseData?.orders?.length > 0 &&
                searchResponseData?.orders?.map((item, index) => (
                  <OrderCard
                    key={index}
                    data={item}
                    isOpenFullCard={searchState?.inputValue?.trim()?.length > 1}
                  />
                ))}
            </motion.div>
          )}
      </LayoutGroup>
    </div>
  );
}
