import styles from "./NotFoundPage.module.css";
import Header from "../../components/Header/Header";
import Button from "../../components/ui/Button/Button";
import SVGIcon from "../../components/SVGIcon";
import useTitle from "../../utils/useTitle";
import Footer from "../../components/Footer/Footer";

/**
 * Компонент страницы 404 ошибки.
 *
 * @return {JSX.Element} React-элемент страницы.
 */
const NotFoundPage = () => {
  useTitle("Страница не найдена");

  const Cover = () => {
    return (
      <div className={styles.cover}>
        <div className={styles.image} />
        <div className={styles.text}>
          Похоже, мы не можем найти нужную Вам страницу
        </div>
      </div>
    );
  };

  const HomeButton = () => {
    const handleClick = () => window.location.replace("/");

    return (
      <Button
        spaceV={10}
        spaceH={16}
        fontSize={15}
        radius={6}
        gap={8}
        onClick={handleClick}
      >
        <SVGIcon name={"home"} size={16} color={"currentColor"} /> На главную
      </Button>
    );
  };

  return (
    <>
      <Header isBackButton />
      <main className={styles.container}>
        <Cover />
        <HomeButton />
      </main>
      <Footer logo />
    </>
  );
};

export default NotFoundPage;
