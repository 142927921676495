import { useEffect, useRef, useState } from "react";
import styles from "./List.module.css";
import Title from "../Title/Title";
import Spinner from "../Spinner/Spinner";
import { motion } from "framer-motion";

/**
 * Компонент списка.
 *
 * @param {Object} props Свойства компонента.
 * @param {Function} props.onClickItem Функция для изменения значения в инпуте селекта.
 * @param {value[]} props.list Данные для списка.
 * @param {Function} props.onCloseList Функция для закрытия списка.
 * @param {boolean} props.nowrap Флаг для отмены переноса слов.
 * @param {boolean} props.isNotFoundMessage Флаг для вывода сообщения об отсутствии данных.
 * @param {boolean} props.isLoading Флаг для обозначения состояния загрузки.
 * @param {boolean} props.isAbsolute Флаг для применения абсолютного позиционирования.
 * @param {boolean} props.isKeyActivated Флаг для активации нажатия/перемещия по списку кнопками с клавиатуры
 * @return {JSX.Element} React-элемент списка.
 */

export default function List({
  onClickItem,
  list,
  onCloseList,
  nowrap = false,
  isNotFoundMessage = false,
  isLoading = false,
  isAbsolute = false,
  isKeyActivated = false,
}) {
  const listRef = useRef(null);

  const [maxHeight, setMaxHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const [activListCardIndex, setActiveListCardIndex] = useState(0);

  //   Закрытие списка при нажатии вне его области

  function onClose(e) {
    if (
      listRef?.current &&
      !listRef?.current.contains(e.target) &&
      !listRef?.current.parentNode.parentNode.contains(e.target)
    ) {
      onCloseList();
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", onClose);
    return () => document.removeEventListener("mousedown", onClose);
  }, []);

  useEffect(() => {
    setScreenWidth(document.documentElement.clientWidth);

    window.addEventListener("resize", () => {
      setMaxHeight(getMaxHeight());
    });
    return () => {
      window.removeEventListener("resize", () => {
        setMaxHeight(getMaxHeight());
      });
    };
  }, []);

  useEffect(() => {
    if (listRef?.current) {
      setMaxHeight(getMaxHeight());
    }
  }, [screenWidth, listRef?.current]);

  function getMaxHeight() {
    if (listRef?.current) {
      let newMaxHeight = 0;
      const main = document.querySelector("main");
      const rect = main.getBoundingClientRect();
      const rectList = listRef.current.getBoundingClientRect();
      newMaxHeight = rect.bottom - rectList.top;
      if (isAbsolute) {
        const footer = document.querySelector("footer");
        const rectFooter = footer.getBoundingClientRect();
        newMaxHeight = Math.ceil(
          rectFooter.bottom - rectFooter.height / 3 - rectList.top
        );
      }
      return newMaxHeight;
    }
  }

  const handleKeyDown = (e) => {
    e.preventDefault();
    if (e.key === "ArrowDown") {
      if (activListCardIndex === list?.length - 1) {
        !isAbsolute &&
          document.querySelector("#list")?.children[0]?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        setActiveListCardIndex(0);
      } else {
        !isAbsolute &&
          document
            .querySelector("#list")
            ?.children[activListCardIndex]?.scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
        setActiveListCardIndex((prev) => prev + 1);
      }
    }
    if (e.key === "ArrowUp") {
      if (activListCardIndex === 0) {
        !isAbsolute &&
          document
            .querySelector("#list")
            ?.children[list?.length - 1]?.scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
        setActiveListCardIndex(list?.length - 1);
      } else {
        !isAbsolute &&
          document
            .querySelector("#list")
            ?.children[activListCardIndex - 1]?.scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
        setActiveListCardIndex((prev) => prev - 1);
      }
    }
    if (e.key === "Enter") {
      onClickItem(list?.[activListCardIndex]?.id);
      onCloseList();
    }
  };

  /**
   * Компонент элемента списка.
   *
   * @param {Object} props Свойства компонента.
   * @param {string} props.name Наименование элемента.
   * @param {number} props.id Id элемента.
   * @param {Function} props.onClickItem Функция, которая выполняется при нажатии на элемент.
   * @param {number} props.index Index элемента.
   * @return {JSX.Element} React-элемент.
   */

  function Item({ id, name, onClickItem, index }) {
    function onClick() {
      onClickItem(id);
      onCloseList();
    }

    return (
      <button
        className={`${styles.item} ${
          activListCardIndex === index && isKeyActivated ? styles.active : ""
        } `}
        onClick={onClick}
        tab={index}
      >
        {name}
      </button>
    );
  }

  /**
   * Компонент для отображения сообщения об отсутствии результатов поиска.
   *
   * @return {JSX.Element} React-элемент сообщения.
   */
  const NotFoundMessage = () => {
    return (
      <div className={styles.notFoundContainer}>
        <Title level={2}>Ничего не найдено</Title>
        <div className={styles.notFoundText}>
          Проверьте, нет ли ошибок в введённом запросе
        </div>
      </div>
    );
  };

  return (
    <>
      <motion.div
        exit={{ opacity: 0, ...(isAbsolute ? "" : { height: 0 }) }}
        initial={{ opacity: 0, ...(isAbsolute ? "" : { height: "auto" }) }}
        animate={{
          opacity: 1,
        }}
        transition={{ duration: 0.3 }}
        ref={listRef}
        className={`${styles.listContainer}  ${nowrap ? styles.nowrap : ""} ${
          isAbsolute ? styles.absolute : ""
        }`}
      >
        {!isNotFoundMessage && !isLoading && (
          <div
            onKeyDown={handleKeyDown}
            id={"list"}
            tabIndex={"0"}
            style={
              list?.length < 7 && !isAbsolute
                ? {}
                : { maxHeight: `${maxHeight}px` }
            }
            className={`${styles.list} customScrollList ${
              !isAbsolute
                ? list?.length < 7
                  ? styles.notScroll
                  : styles.minHeight
                : ""
            }
            
            `}
          >
            {list?.map(({ id, name }, index) => (
              <Item
                id={id}
                name={name}
                key={id}
                onClickItem={onClickItem}
                index={index}
              />
            ))}
          </div>
        )}
        {(isNotFoundMessage || isLoading) && (
          <div
            className={`${styles.list} customScrollList ${styles.minHeight} ${styles.justifyCenter}`}
          >
            {isNotFoundMessage ? <NotFoundMessage /> : <Spinner />}
          </div>
        )}
      </motion.div>
    </>
  );
}
